import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Switch, useLocation } from 'react-router-dom';

import { initializeT } from 'UI/lib';
import RestrictedRoute from 'UI/wrappers/restricted-route';
import RootWrapper from 'UI/wrappers/root/root';

import SplashScreen from './ui/containers/SplashScreen';

const Restrict = lazy(() => import('UI/pages/restrict/restrict'));

const RouterSwitch = () => {
  const location = useLocation();
  const { t, ready } = useTranslation();

  const [isTInitialized, setIsTInitialized] = useState(false);
  useEffect(() => {
    if (ready) {
      initializeT(t);
      setIsTInitialized(true);
    }
  }, [ready, t]);

  if (!isTInitialized) {
    return <SplashScreen />;
  }

  const { search, hash } = location;
  return (
    <RootWrapper>
      <Switch>
        <RestrictedRoute
          fallbackPath="/app"
          path="/app"
          render={props => (
            <Suspense fallback={<SplashScreen />}>
              <Restrict {...props} />
            </Suspense>
          )}
        />
        <Redirect
          to={{
            pathname: '/app/overview',
            search,
            hash,
          }}
        />
      </Switch>
    </RootWrapper>
  );
};

export default RouterSwitch;
