import { MarketOpportunityFields } from './MarketOpportunityFields.fragment';
import gql from 'graphql-tag';

export const GET_MARKET_OPPORTUNITIES = gql`
  ${MarketOpportunityFields}

  query GET_MARKET_OPPORTUNITIES(
    $perPage: Int
    $page: Int
    $filter: MarketOpportunityFilterInputType
    $sortBy: String
    $sortDirection: String
  ) {
    marketOpportunities(
      perPage: $perPage
      page: $page
      filter: $filter
      sortBy: $sortBy
      sortDirection: $sortDirection
    ) {
      __typename
      count
      items {
        ...MarketOpportunityFields
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;
