import gql from 'graphql-tag';

const QUERY = gql`
  query GLOBAL_SEARCH(
    $text: String!
    $limit: Int
    $isCompanySpecific: Boolean
    $types: [FilterGlobalSearchTypes]
    $language: Language
    $organizationId: ID
    $indirect: Boolean
    $isSkillInventory: Boolean
    $isSharedSkillsOnly: Boolean
    $isPrioritizingCompanySpecific: Boolean
    $isSearchingWholeCompany: Boolean
    $companyOpportunityType: CapabilityTypeEnum
    $bypassCustomerSpecificSynonyms: Boolean
    $viewEntireOrganization: Boolean
  ) {
    search(
      text: $text
      companyOpportunityType: $companyOpportunityType
      limit: $limit
      types: $types
      isCompanySpecific: $isCompanySpecific
      language: $language
      organizationId: $organizationId
      indirect: $indirect
      isSkillInventory: $isSkillInventory
      isSharedSkillsOnly: $isSharedSkillsOnly
      isPrioritizingCompanySpecific: $isPrioritizingCompanySpecific
      isSearchingWholeCompany: $isSearchingWholeCompany
      bypassCustomerSpecificSynonyms: $bypassCustomerSpecificSynonyms
      viewEntireOrganization: $viewEntireOrganization
    ) {
      ... on SkillSynonym {
        __typename
        _id
        companySpecific {
          _id
          isLogoVisible
          customName
        }
        synonymString
        synonymLang
        synonymId
        skill {
          _id
          trends {
            _id
            trendIcon
          }
        }
        synonymLevelDescriptions {
          _id
          level
          description
        }
      }
      ... on JobSynonym {
        __typename
        _id
        name
        trendIcon
      }
      ... on CompanyOpportunity {
        __typename
        _id
        name
      }
    }
  }
`;

export default QUERY;
