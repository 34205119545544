import React, { useCallback, useContext, useState } from 'react';

const FilterExpandToggleContext = React.createContext();
const useFilterExpandToggle = () => useContext(FilterExpandToggleContext);

const FilterExpandToggle = ({ children }) => {
  const [activeFilterName, setActiveFilterName] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleIsExpanded = useCallback(() => setIsExpanded(oldIsExpanded => !oldIsExpanded), []);
  return (
    <FilterExpandToggleContext.Provider
      value={{
        activeFilterName,
        setActiveFilterName,
        isExpanded,
        toggleIsExpanded,
        setIsExpanded,
      }}
    >
      {children}
    </FilterExpandToggleContext.Provider>
  );
};

export {
  FilterExpandToggleContext,
  FilterExpandToggle as FilterExpandToggleProvider,
  useFilterExpandToggle,
};
