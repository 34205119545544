import React from 'react';

import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { useMessageContext } from 'UI/contexts';
import { useUserSkills } from 'UI/contexts/userSkills';
import { clientLogger, useT } from 'UI/lib';

import i18next from 'i18next';
import get from 'lodash/get';

const styles = theme => {
  const defaultStyle = {
    ...get(theme, 'customElements.snackBar.default', {}),
  };
  const buttonStyle = {
    border: '1px solid',
    borderColor: defaultStyle.color || '#fff',
    color: defaultStyle.color || '#fff',
    '&:hover': {
      color: defaultStyle.backgroundColor,
      backgroundColor: defaultStyle.color || '#fff',
    },
  };
  return {
    dialogContentText: {
      fontSize: 16,
      color: defaultStyle.color || '#fff',
    },
    cancel: {
      ...buttonStyle,
    },
    confirm: {
      ...buttonStyle,
    },
  };
};

const useStyles = makeStyles(styles);

const ReplaceSkillWithSynonymModal = ({ userSavedSkill, newSkill, onFinish, text }) => {
  const { skillUpdate } = useUserSkills();
  const message = useMessageContext();
  const t = useT();
  const classes = useStyles();

  const handleConfirm = async () => {
    try {
      await skillUpdate({
        item: userSavedSkill,
        options: {
          synonymId: newSkill.synonymId,
        },
        shouldRefetch: true,
      });
      message.setMessage({
        type: 'success',
        text: t('restrict:skills.replaced_synonym_skill_successfully'),
      });
    } catch (e) {
      clientLogger.error(e);

      message.setMessage({
        type: 'error',
        text: t('common:default_error_message'),
      });
    } finally {
      onFinish();
    }
  };
  return (
    <>
      <Typography className={classes.dialogContentText}>{text}</Typography>
      <Typography className={classes.dialogContentText}>
        {t('restrict:skills.new_skill_is_synonym')}
      </Typography>
      <Grid container spacing={3} sx={{ mt: 10 }} justifyContent="flex-end">
        <Grid item>
          <Button className={classes.cancel} onClick={onFinish}>
            <>{i18next.t('common:no')}</>
          </Button>
        </Grid>
        <Grid item>
          <Button className={classes.confirm} onClick={handleConfirm}>
            <>{i18next.t('common:yes')}</>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ReplaceSkillWithSynonymModal;
