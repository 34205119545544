import { useMemo } from 'react';

import { gql, useQuery } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import { clientLogger } from 'UI/lib';

import { USER_SKILL_FRAGMENT } from '../../../../../../skills/SkillsTab/getViewerSkills.query';

const useMembersSkillProfile = ({ personId }) => {
  const config = useConfig();
  const skillSynonymPoliciesPreset =
    config.cms?.components?.MemberSkillsProfile?.skillSynonymPoliciesPreset;

  const { data, loading: isLoading } = useQuery(
    gql`
      query GET_MEMBER_SKILLS_PROFILE($personId: ID!, $policiesPreset: PoliciesPresetInput) {
        user(personId: $personId) {
          profile {
            name
          }
          skills {
            ${USER_SKILL_FRAGMENT}
          }
        }
      }
    `,
    {
      variables: {
        personId,
        policiesPreset: { preset: skillSynonymPoliciesPreset, params: { personId } },
      },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      onError: error => {
        clientLogger.error(`useMembersSkillProfile - ${error.message}`, error);
      },
    }
  );

  const skillsWithSynonyms = useMemo(
    () => data?.user?.skills.filter(userSkill => userSkill.skill?.synonym),
    [data]
  );

  return {
    isLoading,
    skills: skillsWithSynonyms,
    userName: data?.user?.profile?.name,
  };
};

export default useMembersSkillProfile;
