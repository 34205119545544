import { useMemo } from 'react';

import { useUserSkills } from '../../../../../../contexts/userSkills';

const useStillValidSkills = persistedSelectedSkills => {
  const {
    state: { items: allSkills, loading: isLoading },
  } = useUserSkills();

  const stillValidSkills = useMemo(() => {
    if (isLoading || !allSkills || !persistedSelectedSkills) {
      return null;
    }

    return persistedSelectedSkills.filter(({ skillId }) => {
      const existingSkill = allSkills.find(skill => skill.skillId === skillId);
      const isUpToDate = existingSkill && existingSkill.improve;
      return isUpToDate;
    });
  }, [persistedSelectedSkills, allSkills, isLoading]);

  return {
    isLoading,
    stillValidSkills,
  };
};

export default useStillValidSkills;
