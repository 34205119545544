import React, { useContext } from 'react';

import { useAddSkillsToValidate } from '../containers/SkillValidation/AllAssignedSkills/useAddSkillsToValidate';

const UserValidateSkillsContext = React.createContext();

const UserSkillsToValidate = ({ children }) => {
  const { addSkillsToValidate } = useAddSkillsToValidate();

  return (
    <UserValidateSkillsContext.Provider value={{ createMany: addSkillsToValidate }}>
      {children}
    </UserValidateSkillsContext.Provider>
  );
};

const useUserValidateSkills = () => useContext(UserValidateSkillsContext);

const withUserValidateSkills = Component => props => {
  const data = useUserValidateSkills();
  return <Component {...props} userValidateSkills={data} />;
};

export {
  UserValidateSkillsContext,
  UserSkillsToValidate as UserValidateSkillsProvider,
  useUserValidateSkills,
  withUserValidateSkills,
};
