import React, { useCallback, useContext, useMemo, useState } from 'react';

import usePersistedFilters2 from './../pages/restrict/learn-v2/Course/graphql/usePersistedFilters2';
import usePersistSelectedFilterBar from './../pages/restrict/learn-v2/Course/graphql/usePersistSelectedFilterBar';
import useSelectedSkills from './../pages/restrict/learn-v2/Course/SkillsFilterPanel/hooks/useSelectedSkills';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

const LearningV2FiltersContext = React.createContext();
const useLearningV2Filters = () => useContext(LearningV2FiltersContext);

const LearningV2Filters = ({ children }) => {
  const {
    persistedSelectedLanguages,
    persistedSelectedKeywords,
    persistedSelectedHasAttended,
    persistedSelectedSourceIds,
  } = usePersistedFilters2();

  const savedFilters = useMemo(
    () =>
      omitBy(
        {
          languages: persistedSelectedLanguages,
          keywords: persistedSelectedKeywords,
          hasAttended: persistedSelectedHasAttended,
          sourceIds: persistedSelectedSourceIds,
        },
        isUndefined
      ),
    [
      persistedSelectedLanguages,
      persistedSelectedKeywords,
      persistedSelectedHasAttended,
      persistedSelectedSourceIds,
    ]
  );
  const [filters, setFilters] = useState(savedFilters);

  const { persistSelectedFilterBar } = usePersistSelectedFilterBar();
  const { selectedSkills } = useSelectedSkills();

  const handleFilterContextData = useCallback(() => {
    setFilters(() => ({ ...savedFilters }));
  }, [savedFilters]);

  const handleLanguageChange = useCallback(
    language => {
      setFilters(state => ({ ...state, languages: language }));
      persistSelectedFilterBar({
        ...savedFilters,
        languages: language,
        skills: selectedSkills,
      });
    },
    [selectedSkills, persistSelectedFilterBar, savedFilters]
  );

  const handleKeyWordsChange = useCallback(
    keywords => {
      setFilters(state => ({ ...state, keywords }));
      persistSelectedFilterBar({
        ...savedFilters,
        keywords,
        skills: selectedSkills,
      });
    },
    [selectedSkills, persistSelectedFilterBar, savedFilters]
  );

  const handleSourceIdsChange = useCallback(
    sourceIds => {
      setFilters(state => ({ ...state, sourceIds }));
      persistSelectedFilterBar({
        ...savedFilters,
        sourceIds,
        skills: selectedSkills,
      });
    },
    [selectedSkills, persistSelectedFilterBar, savedFilters]
  );

  const handleHasAttendedChange = useCallback(
    hasAttended => {
      setFilters(state => ({ ...state, hasAttended }));
      persistSelectedFilterBar({
        ...savedFilters,
        hasAttended,
        skills: selectedSkills,
      });
    },
    [selectedSkills, persistSelectedFilterBar, savedFilters]
  );

  const handleSkills = useCallback(
    skills => {
      setFilters(state => ({ ...state, skills }));
      persistSelectedFilterBar({
        ...savedFilters,
        skills: skills,
      });
    },
    [persistSelectedFilterBar, savedFilters]
  );

  const resetAllLearnV2Filters = useCallback(() => {
    setFilters({ languages: [], keywords: [], sourceIds: [], hasAttended: false });
    persistSelectedFilterBar({
      skills: [],
      languages: [],
      keywords: [],
      sourceIds: [],
      hasAttended: false,
    });
  }, [persistSelectedFilterBar]);

  return (
    <LearningV2FiltersContext.Provider
      value={{
        languages: filters.languages,
        keywords: filters.keywords,
        sourceIds: filters.sourceIds,
        hasAttended: filters.hasAttended,
        handleLanguageChange,
        handleKeyWordsChange,
        handleSourceIdsChange,
        handleHasAttendedChange,
        resetAllLearnV2Filters,
        handleFilterContextData,
        handleSkills,
      }}
    >
      {children}
    </LearningV2FiltersContext.Provider>
  );
};

export {
  LearningV2FiltersContext,
  LearningV2Filters as LearningV2FiltersProvider,
  useLearningV2Filters,
};
