import { Tooltip } from '@mui/material';

const BasicTooltip = ({
  placement = 'top',
  title,
  sxTooltip,
  sxArrow,
  disablePortal,
  children,
  disableHoverListener,
  arrow = true,
  ...props
}) => {
  return (
    <Tooltip
      placement={placement}
      arrow={arrow}
      disableHoverListener={disableHoverListener}
      title={title}
      componentsProps={{
        tooltip: {
          sx: () => ({
            boxShadow: `#1B212C1F 0px ${placement !== 'top' ? '-8px' : '8px'} 16px -2px`,
            backgroundColor: '#fff',
            color: '#000',
            fontSize: 14,
            margin: 5,
            textAlign: 'left',
            padding: '8px',
            borderRadius: '8px',
            maxWidth: 300,
            ...sxTooltip,
          }),
        },
        arrow: {
          sx: () => ({
            color: '#fff',
            ...sxArrow,
          }),
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};

export default BasicTooltip;
