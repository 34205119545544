import { OpportunitySkillFields } from 'UI/pages/restrict/opportunities/OpportunitySkillFields.fragment';

import {
  CompanyOpportunityFields,
  CompanyOpportunitySkillsTypeFields,
} from '../CompanyOpportunities/CompanyOpportunityFields.fragment';
import gql from 'graphql-tag';

export const GET_ASSIGNED_OPPORTUNITIES = gql`
  ${CompanyOpportunityFields}
  ${OpportunitySkillFields}
  ${CompanyOpportunitySkillsTypeFields}

  query GET_ASSIGNED_OPPORTUNITIES(
    $targetUserId: String
    $withDetails: Boolean! = false
    $page: Int
    $perPage: Int
    $pagination: Boolean! = false
  ) {
    assignedOpportunities(
      targetUserId: $targetUserId
      withDetails: $withDetails
      page: $page
      perPage: $perPage
    ) {
      items {
        ...CompanyOpportunityFields
        matching @include(if: $withDetails) {
          ...CompanyOpportunitySkillsTypeFields
          related {
            ...OpportunitySkillFields
          }
        }
        missing @include(if: $withDetails) {
          ...CompanyOpportunitySkillsTypeFields
        }
      }
      pageInfo @include(if: $pagination) {
        currentPage
        itemCount
        perPage
        pageCount
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;
