import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { VALIDATION_TYPES_ARRAY } from 'UI/constants/validationTypes';
import { GET_ALL_ASSIGNED_SKILL_VALIDATIONS } from 'UI/containers/SkillValidation/AllAssignedSkills/getAllAssignedSkillValidations.query';
import { useMessageContext } from 'UI/contexts/message';
import useConfig from 'UI/customHooks/useConfig';
import updateQueryCache from 'UI/customHooks/useUpdateQueryCache';
import { clientLogger, useT } from 'UI/lib';
import { GET_COMPANY_OPPORTUNITIES_DETAILS } from 'UI/pages/restrict/opportunities/CompanyOpportunities/companyOpportunityDetails';

import { getBrowserUserCredentials } from '../../auth/userCredentials';
import getApolloClient from '../../getApolloClient';
import {
  userOrganizationLatestSkillFragmentUpdate,
  userSkillFragmentUpdate,
} from '../apolloStorage';
import { userSkillsFragmentUpdate } from '../apolloStorage/userSkills';
import { getFieldsFromGqlQuery } from '../lib/getFieldsFromGqlQuery';
import {
  MANAGER_DELETE_SUGGESTION,
  USER_SKILL_DELETE,
  USER_SKILL_UPDATE,
  USER_SKILLS_CREATE,
  USER_SKILLS_UPDATE,
  USER_VALIDATION_SKILLS,
} from '../mutations/userSkills';
import { GET_COMPANY_OPPORTUNITIES } from '../pages/restrict/opportunities/CompanyOpportunities/companyOpportunities.query';
import { GET_MARKET_OPPORTUNITIES } from '../pages/restrict/opportunities/MarketOpportunities/marketOpportunities.query';
import { GET_ASSIGNED_OPPORTUNITIES } from '../pages/restrict/opportunities/MyRoles/myRoles.query';
import { updateSkillsInCachedOpportunities } from '../pages/restrict/opportunities/updateSkillsInCachedOpportunities';
import { GET_VIEWER_SKILLS } from '../pages/restrict/skills/SkillsTab/getViewerSkills.query';
import useViewerSkills from '../pages/restrict/skills/SkillsTab/useViewerSkills';
import {
  GET_FOLLOWED_OPPORTUNITIES,
  GET_VIEWER_EXPLORE_OPPORTUNITIES,
  GET_VIEWER_FOLLOWED_OPPORTUNITIES,
} from '../queries/userOpportunities';
import { useUserId } from './user';
import { useUserOrganizationsContext } from './userOrganizations';
import i18n from 'i18next';
import difference from 'lodash/difference';
import differenceBy from 'lodash/differenceBy';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import merge from 'lodash/merge';
import { v4 as getUuid } from 'uuid';

const UserSkillsContext = React.createContext();

const UserSkills = ({ children }) => {
  const { palyxToken } = getBrowserUserCredentials();
  const message = useMessageContext();
  const t = useT();
  const config = useConfig();
  const { pathname } = useLocation();
  const userOrganizationsContext = useUserOrganizationsContext();
  const selectedOrganizationId = get(userOrganizationsContext, 'state.selectedOrganizationId');
  const userOrganization = get(userOrganizationsContext, 'state.organization.item.organization');
  const showMarketTab = config?.cms?.features?.enabledMarketRole?.showMarketTab;
  const allowMarket =
    showMarketTab ?? (userOrganization ? userOrganization.showMarketOpportunities : true);
  const skillsProfileLimit = get(config, 'cms.features.skills.skillsProfileLimit');

  const skillsProfileSynonymPoliciesPreset = get(
    config,
    'cms.pages.skills.skillSynonymPoliciesPreset'
  );

  //TODO: Use feature flag to hide the widget and disable this ?
  //Explore opportunities are no added to Dashboard.jsx
  const hasFollowedOpportunities = false;

  //TODO: Use feature flag to hide the widget and disable this ?
  //This component is added in Dashboard.jsx
  const hasExploreOpportunities = true;

  const skillToValidateSynonymPoliciesPreset = get(
    config,
    'cms.components.SelfAssignedSkills.skillSynonymPoliciesPreset'
  );

  const dashboardQueriesToRefetch = useMemo(() => {
    const queries = [];
    if (hasFollowedOpportunities) {
      queries.push(
        { query: GET_FOLLOWED_OPPORTUNITIES },
        { query: GET_VIEWER_FOLLOWED_OPPORTUNITIES }
      );
    }
    if (hasExploreOpportunities) {
      queries.push({
        query: GET_VIEWER_EXPLORE_OPPORTUNITIES,
        variables: { showMarketRoles: allowMarket },
      });
    }
    return queries;
  }, [allowMarket, hasExploreOpportunities, hasFollowedOpportunities]);

  const userId = useUserId();

  const skillsCreateMany = useCallback(
    (skillsToAdd, state, queries) => {
      if (state.count + skillsToAdd.length > skillsProfileLimit) {
        return t('restrict:skills.max_amount_exceeded', {
          amount: skillsToAdd.length,
          availableAmount: skillsProfileLimit - state.count,
        });
      }

      const queryObjectEmptyStructure = getFieldsFromGqlQuery(GET_VIEWER_SKILLS);

      const { mutationInput, optimisticResponse } = skillsToAdd.reduce(
        (results, skillToAdd) => {
          const {
            skill,
            origin = 'manual',
            isLiked = false,
            expertise,
            isImprove = false,
            proofPointId = null,
          } = skillToAdd;

          const temporaryId = `temp-id-${getUuid()}`;

          results.optimisticResponse.push({
            __typename: 'UserSkillPayload',
            recordId: temporaryId,
            record: merge({}, queryObjectEmptyStructure, {
              _id: temporaryId,
              userId,
              like: isLiked,
              level: expertise,
              improve: isImprove,
              createdAt: new Date(),
              updatedAt: new Date(),
              origin,
              proofPointId,
              skill,
            }),
          });

          results.mutationInput.push({
            synonymId: skill.synonym.synonymId,
            like: isLiked,
            level: expertise,
            improve: isImprove,
            origin,
            proofPointId,
          });

          return results;
        },
        {
          mutationInput: [],
          optimisticResponse: [],
        }
      );

      try {
        getApolloClient()
          .mutate({
            mutation: USER_SKILLS_CREATE,
            variables: {
              record: mutationInput,
              policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
            },
            optimisticResponse: {
              __typename: 'Mutation',
              userSkillCreateMany: optimisticResponse,
            },
            update: (cache, { data: { userSkillCreateMany } }) => {
              updateQueryCache({
                cache,
                query: GET_VIEWER_SKILLS,
                variables: {
                  policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
                },
                update: oldViewerSkills => {
                  return userSkillCreateMany.map(i => i.record).concat(oldViewerSkills);
                },
              });

              updateQueryCache({
                cache,
                query: GET_ALL_ASSIGNED_SKILL_VALIDATIONS,
                variables: {
                  suggestedAction: undefined,
                  policiesPreset: { preset: skillToValidateSynonymPoliciesPreset },
                },
                update: oldSkillsToValidate => {
                  const skillIdsToRemove = userSkillCreateMany.map(
                    ({ record: { skill } }) => skill._id
                  );

                  const filteredSkills = VALIDATION_TYPES_ARRAY.reduce((acc, prop) => {
                    acc[prop] = oldSkillsToValidate[prop]?.filter(
                      item => !skillIdsToRemove.includes(item.skillId)
                    );
                    return acc;
                  }, {});
                  return {
                    ...oldSkillsToValidate,
                    ...filteredSkills,
                  };
                },
              });

              // Update user opportunities matching/missing skills
              updateSkillsInCachedOpportunities(
                cache,
                userSkillCreateMany
                  .filter(({ record: { level } }) => level !== null)
                  .map(({ record: { skillId } }) => skillId)
              );

              //  Update Organization latest skills cache
              userOrganizationLatestSkillFragmentUpdate(cache, {
                organizationId: selectedOrganizationId,
                data: userSkillCreateMany.map(({ record: userSkill }) => ({
                  skillId: userSkill.skillId,
                  userHas: true,
                })),
              });
            },
            refetchQueries: queries(),
          })
          .then(() => {
            message.setMessage({
              type: 'success',
              text: t('restrict:validate_skills_added_many_skills', {
                count: skillsToAdd.length,
              }),
            });
          })
          .catch(() => {
            message.setMessage({
              text: t('common:default_error_message'),
              type: 'error',
            });
          });
      } catch (error) {
        clientLogger.error(`userSkills.skillsCreateMany: Exception ${error.message}`, error);
        return t('common:default_error');
      }
    },
    [
      selectedOrganizationId,
      t,
      skillsProfileLimit,
      skillsProfileSynonymPoliciesPreset,
      skillToValidateSynonymPoliciesPreset,
      userId,
    ]
  );

  const skillUpdate = useCallback(
    async ({ options, item, shouldRefetch = false }) => {
      const variables = {
        _id: item._id,
        userId,
        skillId: item.skillId,
        ...options,
      };

      const optimisticData = {
        ...item,
        ...options,
      };

      return await getApolloClient().mutate({
        mutation: USER_SKILL_UPDATE,
        variables: {
          record: variables,
          policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
        },
        ...(shouldRefetch && { refetchQueries: ['GET_VIEWER_SKILLS'] }),
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillUpdateById: {
            __typename: 'UserSkillPayload',
            recordId: item._id,
            record: {
              __typename: 'UserSkill',
              ...optimisticData,
            },
          },
        },
        update: (proxy, { data: { userSkillUpdateById = {} } }) => {
          const { record = {} } = userSkillUpdateById;

          userSkillFragmentUpdate(proxy, record);
        },
      });
    },
    [userId]
  );

  const skillsValidate = useCallback(
    async (ids, skillIds, state) => {
      const existingIdsSet = state.items.map(obj => obj.skillId);
      const newIds = difference(skillIds, existingIdsSet);
      const numberOfNewIds = newIds?.length;
      if (state.count + numberOfNewIds > skillsProfileLimit) {
        return t('restrict:skills.max_amount_exceeded', {
          amount: ids.length,
          availableAmount: skillsProfileLimit - state.count,
        });
      }

      return await getApolloClient()
        .mutate({
          mutation: USER_VALIDATION_SKILLS,
          variables: {
            personValidateSkillIds: ids,
            policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
          },
          update: (cache, { data: { userValidateSkillConfirmMany } }) => {
            const newSkillsElements = [];
            updateQueryCache({
              cache,
              query: GET_VIEWER_SKILLS,
              variables: {
                policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
              },
              update: oldViewerSkills => {
                const newSkills = differenceBy(
                  userValidateSkillConfirmMany,
                  oldViewerSkills,
                  '_id'
                );
                newSkillsElements.push(...newSkills);
                return newSkills.map(i => i).concat(oldViewerSkills);
              },
            });
            updateQueryCache({
              cache,
              query: GET_ALL_ASSIGNED_SKILL_VALIDATIONS,
              variables: {
                suggestedAction: undefined,
                policiesPreset: { preset: skillToValidateSynonymPoliciesPreset },
              },
              update: oldSkillsToValidate => {
                const skillIdsToRemove = userValidateSkillConfirmMany.map(({ skill }) => skill._id);

                const filteredSkills = VALIDATION_TYPES_ARRAY.reduce((acc, prop) => {
                  acc[prop] = oldSkillsToValidate[prop]?.filter(
                    item => !skillIdsToRemove.includes(item.skillId)
                  );
                  return acc;
                }, {});
                return {
                  ...oldSkillsToValidate,
                  ...filteredSkills,
                };
              },
            });
            updateSkillsInCachedOpportunities(
              cache,
              newSkillsElements.filter(({ level }) => level !== null).map(({ skillId }) => skillId)
            );
            userOrganizationLatestSkillFragmentUpdate(cache, {
              organizationId: selectedOrganizationId,
              data: newSkillsElements.map(({ skill }) => ({
                skillId: skill.skillId,
                userHas: true,
              })),
            });
          },
        })
        .then(() => {
          message.setMessage({
            type: 'success',
            text: t('restrict:validate_skills_added_many_skills', {
              count: ids.length,
            }),
          });
        })
        .catch(() => {
          message.setMessage({
            text: t('common:default_error_message'),
            type: 'error',
          });
        });
    },
    [skillToValidateSynonymPoliciesPreset, skillsProfileSynonymPoliciesPreset]
  );

  const skillsUpdateMany = useCallback(
    async (updatedSkills, state) => {
      const stateSkillskeyBy = keyBy(state.items, '_id');

      return await getApolloClient().mutate({
        mutation: USER_SKILLS_UPDATE,
        variables: {
          records: updatedSkills.map(sk => ({
            ...sk,
            skillId: stateSkillskeyBy[sk._id].skillId,
          })),
          policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillsUpdateMany: updatedSkills.map(updatedSkill => ({
            ...stateSkillskeyBy[updatedSkill._id],
            ...updatedSkill,
            __typename: 'UserSkill',
          })),
        },
        update: (proxy, { data: { userSkillsUpdateMany = [] } }) => {
          userSkillsFragmentUpdate(proxy, userSkillsUpdateMany, {
            policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
          });
        },
      });
    },
    [skillsProfileSynonymPoliciesPreset]
  );

  const skillRemove = useCallback(
    async (skillId, skillsToCache, queries) => {
      return await getApolloClient().mutate({
        mutation: USER_SKILL_DELETE,
        variables: {
          skillId,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userSkillRemoveById: true,
        },
        update: async cache => {
          const dataFieldName =
            GET_VIEWER_SKILLS.definitions[0]?.selectionSet?.selections[0]?.name?.value;

          if (dataFieldName) {
            await cache.writeQuery({
              query: GET_VIEWER_SKILLS,
              variables: { policiesPreset: { preset: skillsProfileSynonymPoliciesPreset } },
              data: { [dataFieldName]: skillsToCache },
            });
          } else {
            updateQueryCache({
              cache,
              query: GET_VIEWER_SKILLS,
              variables: {
                policiesPreset: { preset: skillsProfileSynonymPoliciesPreset },
              },
              update: oldViewerSkills => {
                return oldViewerSkills.filter(
                  oldViewerSkill => oldViewerSkill.skill._id !== skillId
                );
              },
            });
          }
        },
        refetchQueries: queries(),
      });
    },
    [skillsProfileSynonymPoliciesPreset]
  );

  const skillSuggestionRemove = useCallback(
    async (skillIds, userId, id) => {
      await getApolloClient()
        .mutate({
          mutation: MANAGER_DELETE_SUGGESTION,
          variables: {
            skillIds,
            userId,
          },
          update: cache => {
            const normalizedId = cache.identify({
              id: id,
              __typename: 'UserSkill',
            });
            cache.modify({
              id: normalizedId,
              fields: {
                suggestion() {
                  return null;
                },
              },
            });
            cache.gc();
          },
        })
        .then(() => {
          message.setMessage({
            type: 'success',
            text: t('restrict:skills_page.more_menu.message.delete_suggestion_success'),
          });
        });
    },
    [message, t]
  );

  const { skills, isLoading, refetch } = useViewerSkills({
    skillSynonymPoliciesPreset: skillsProfileSynonymPoliciesPreset,
    skip: !palyxToken,
  });

  useEffect(() => {
    i18n.on('languageChanged', refetch);
    return () => i18n.off('languageChanged', refetch);
  }, [refetch]);

  const providerValue = useMemo(() => {
    const state = {
      count: (skills && skills.length) || 0,
      loading: isLoading,
      items: skills || [],
    };
    const queries = () => {
      if (pathname.indexOf('/app/overview') > -1) {
        return dashboardQueriesToRefetch;
      }

      if (pathname.indexOf('/app/opportunities/market') > -1) {
        return [GET_MARKET_OPPORTUNITIES];
      }

      if (pathname.indexOf('/app/opportunities/company') > -1) {
        return [GET_COMPANY_OPPORTUNITIES, GET_COMPANY_OPPORTUNITIES_DETAILS];
      }

      if (pathname.indexOf('/app/opportunities/my-roles') > -1) {
        return [GET_ASSIGNED_OPPORTUNITIES];
      }

      return [];
    };
    return {
      state,
      skillsCreateMany: personSkills => skillsCreateMany(personSkills, state, queries),
      skillsUpdateMany: skillsUpdate => skillsUpdateMany(skillsUpdate, state),
      skillUpdate,
      skillSuggestionRemove: (skillIds, userId, id) => skillSuggestionRemove(skillIds, userId, id),
      skillRemove: (skillId, skillsToCache) => skillRemove(skillId, skillsToCache, queries),
      skillsValidate: (ids, skillIds) => skillsValidate(ids, skillIds, state),
    };
  }, [
    skills,
    isLoading,
    skillRemove,
    skillUpdate,
    skillsCreateMany,
    skillsUpdateMany,
    pathname,
    dashboardQueriesToRefetch,
    skillSuggestionRemove,
    skillsValidate,
  ]);

  return <UserSkillsContext.Provider value={providerValue}>{children}</UserSkillsContext.Provider>;
};

const useUserSkills = () => useContext(UserSkillsContext);

const withUserSkills = Component => props => {
  const data = useUserSkills();
  return <Component {...props} skills={data} />;
};

export { UserSkillsContext, UserSkills as UserSkillsProvider, useUserSkills, withUserSkills };
