import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SkillExpertise from 'UI/components/SkillExpertise';
import { useT } from 'UI/lib';

const StyledTypography = styled(props => (
  <Typography
    sx={{ fontWeight: props.bold ? '600' : 'inherit', color: props.bold ? '#000' : '#616161' }}
    data-test={props.dataTest}
  >
    {props.children}
  </Typography>
))``;
const LevelDescriptions = ({ levelDescriptions }) => {
  return Object.entries(levelDescriptions).map(([key, value]) => {
    if (!value || key === '__typename') {
      return null;
    }
    return (
      <Grid container item xs={12} key={`level-desc-${key}`}>
        <Grid item xs={2} sx={{ letterSpacing: 14 }}>
          <SkillExpertise
            level={key}
            disableTitleText
            dataTest={`modal-view-star-description-${key}`}
          />{' '}
          -
        </Grid>
        <Grid item xs>
          <StyledTypography dataTest={`modal-view-description-${key}`}>{value} </StyledTypography>
        </Grid>
      </Grid>
    );
  });
};
const SynonymInfo = ({ synonymInfo }) => {
  const t = useT();

  return (
    <Grid container spacing={10} sx={{ paddingBottom: 8 }}>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-custom-name-label">
            {t('restrict:company_view.customer_synonyms.default.column.custom_name')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography dataTest="modal-view-custom-name-value">
            {synonymInfo.customName}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-synonym-label">
            {t('restrict:company_view.customer_synonyms.default.column.name')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography dataTest="modal-view-synonym-value">
            {synonymInfo.name}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-language-label">
            {t('restrict:company_view.customer_synonyms.default.column.language')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography dataTest="modal-view-language-value">
            {synonymInfo.language}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-logo-label">
            {t('restrict:company_view.customer_synonyms.default.column.logo')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography>
            {synonymInfo.isLogoVisible ? (
              <CheckIcon dataTest="modal-view-logo-value-true" />
            ) : (
              <CloseIcon dataTest="modal-view-logo-value-false" />
            )}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-created-at-label">
            {t('restrict:company_view.customer_synonyms.default.column.created_at')}
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography dataTest="modal-view-created-at-value">
            {synonymInfo.createdAt}
          </StyledTypography>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item mr={2}>
          <StyledTypography bold dataTest="modal-view-description-label">
            {t('restrict:company_view.customer_synonyms.default.column.description')}:
          </StyledTypography>
        </Grid>
        <Grid item>
          <StyledTypography dataTest="modal-view-description-value">
            {synonymInfo.description}
          </StyledTypography>
        </Grid>
      </Grid>
      {synonymInfo.levelDescriptions && (
        <LevelDescriptions levelDescriptions={synonymInfo.levelDescriptions} />
      )}
    </Grid>
  );
};

export default SynonymInfo;
