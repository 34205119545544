import gql from 'graphql-tag';

const GET_COURSE_OPPORTUNITIES_V2 = gql`
  query getCourseOpportunitiesV2 {
    coursesOpportunities {
      id
      opportunityId
      opportunityName
      type
      skillsGap {
        _id
      }
    }
  }
`;

export { GET_COURSE_OPPORTUNITIES_V2 };
