import gql from 'graphql-tag';

export const COMPANY_OPPORTUNITY_FOLLOW = gql`
  mutation COMPANY_OPPORTUNITY_FOLLOW($_id: ID!, $policiesPreset: String!) {
    companyOpportunityFollow(_id: $_id, policiesPreset: $policiesPreset) {
      __typename
      _id
      follow
    }
  }
`;

export const COMPANY_OPPORTUNITY_TOOGLE_TOP_MEMBERS_VISIBILITY = gql`
  mutation COMPANY_OPPORTUNITY_TOOGLE_TOP_MEMBERS_VISIBILITY(
    $_id: ID!
    $isAnonymous: Boolean!
    $eventId: String
  ) {
    companyOpportunityTopMembersToogleVisibility(
      _id: $_id
      isAnonymous: $isAnonymous
      eventId: $eventId
    ) {
      __typename
      _id
      isAnonymous
    }
  }
`;

export const COMPANY_OPPORTUNITY_UNFOLLOW = gql`
  mutation COMPANY_OPPORTUNITY_UNFOLLOW($_id: ID!) {
    companyOpportunityUnfollow(_id: $_id) {
      __typename
      _id
      follow
    }
  }
`;

export const COMPANY_OPPORTUNITY_REMOVE = gql`
  mutation COMPANY_OPPORTUNITY_REMOVE($_id: ID!) {
    companyOpportunityRemove(_id: $_id)
  }
`;

export const COMPANY_OPPORTUNITY_RESTORE = gql`
  mutation COMPANY_OPPORTUNITY_RESTORE($_id: ID!) {
    companyOpportunityRestore(_id: $_id) {
      __typename
      _id
      covered
      follow
      link
      job {
        _id
        trendIcon
        name
      }
    }
  }
`;
