import { useCallback, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import updateQueryCache from 'UI/customHooks/useUpdateQueryCache';
import { clientLogger } from 'UI/lib';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

const usePersistSelectedFilterBar = () => {
  const PegasusConfig = useConfig();
  const isCourseAttendedFeatureEnabled = get(
    PegasusConfig,
    'cms.features.learningPageV2.isCourseAttendedFeatureEnabled',
    false
  );

  const [persistSelectedFilterBar, { loading, data, error }] = useMutation(
    gql`
      mutation PERSIST_COURSES_USER_FILTERS_V2(
        $keywords: [String]
        $languages: [String]
        $hasAttendedOnly: Boolean
        $sourceIds: [ID]
        $skills: [CoursesSkillSynonymPairInputV2]
      ) {
        coursesPersistFiltersV2(
          keywords: $keywords
          languages: $languages
          hasAttendedOnly: $hasAttendedOnly
          sourceIds: $sourceIds
          skills: $skills
        )
      }
    `
  );

  useEffect(() => {
    if (error) {
      clientLogger.error(`usePersistSelectedFilterBar - ${error.message}`, error);
    }
  }, [error]);

  return {
    persistSelectedFilterBar: useCallback(
      ({ keywords, languages, sourceIds, hasAttendedOnly, skills }) => {
        persistSelectedFilterBar({
          variables: omitBy(
            {
              keywords,
              languages,
              hasAttendedOnly,
              sourceIds,
              skills,
            },
            isUndefined
          ),
          optimisticResponse: {
            coursesPersistFiltersV2: true,
          },
          update: cache =>
            updateQueryCache({
              cache,
              query: gql`
                query GET_COURSES_USER_PERSISTED_FILTERS_V2(
                  $isCourseAttendedFeatureEnabled: Boolean!
                ) {
                  coursesPersistedFiltersV2 {
                    keywords
                    languages
                    hasAttendedOnly @include(if: $isCourseAttendedFeatureEnabled)
                    sourceIds
                    skills {
                      skillId
                      synonymId
                    }
                  }
                }
              `,
              variables: { isCourseAttendedFeatureEnabled },
              update: () => {
                return {
                  keywords,
                  languages,
                  sourceIds,
                  skills,
                };
              },
            }),
        });
      },
      [persistSelectedFilterBar, isCourseAttendedFeatureEnabled]
    ),
    loading,
    data,
    error,
  };
};

export default usePersistSelectedFilterBar;
