import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import { clientLogger } from 'UI/lib';

import { getBrowserUserCredentials } from '../../../../../../auth/userCredentials';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

const allowedLocations = ['/app/personal-development/recommendations', '/app/my-profile/skills'];
const usePersistedFiltersLxp = () => {
  const { palyxToken } = getBrowserUserCredentials();
  const config = useConfig();
  const LXPCoursesSource =
    config.cms?.pages?.personalDevelopment?.recommendations?.courses?.source === 'LXP';
  const location = useLocation();

  const { data: lxpFilters, error, loading } = useQuery(
    gql`
      query GET_COURSES_USER_PERSISTED_FILTERS_LXP {
        coursesPersistedFiltersLxp {
          learningType
          promoted
          skills {
            skillId
            synonymId
          }
        }
      }
    `,
    {
      skip: !palyxToken || !LXPCoursesSource || !allowedLocations.includes(location.pathname),
    }
  );

  useEffect(() => {
    if (error) {
      clientLogger.error(`usePersistedSelectedSkills - ${error.message}`, error);
    }
  }, [error]);

  const persistedSelectedSkills = useMemo(() => {
    const skills = get(lxpFilters, 'coursesPersistedFiltersLxp.skills');
    return skills && skills.map(skill => pick(skill, ['skillId', 'synonymId']));
  }, [lxpFilters]);

  return omitBy(
    {
      isLoading: loading,
      error,
      persistedSelectedSkills,
      isPromoted: lxpFilters?.coursesPersistedFiltersLxp?.promoted,
      learningType: lxpFilters?.coursesPersistedFiltersLxp?.learningType,
      countSkills:
        (lxpFilters?.coursesPersistedFiltersLxp?.skills.length || 0) +
        (lxpFilters?.coursesPersistedFiltersLxp?.learningType?.length || 0) +
        (lxpFilters?.coursesPersistedFiltersLxp?.promoted ? 1 : 0),
    },
    isUndefined
  );
};

export default usePersistedFiltersLxp;
