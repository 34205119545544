import React from 'react';

import useConfig from 'UI/customHooks/useConfig';
import useExplainVideoURL from 'UI/customHooks/useExplainVideoURL';
import { useT } from 'UI/lib';

import ExplainFeatureButton from '../../components/ExplainFeatureButton';

const ReportsDownloadTitle = ({ title }) => {
  const t = useT();
  const config = useConfig();
  const videoUrlAlt = useExplainVideoURL('reports');

  const videoUrl = config?.cms?.features?.modal?.reports?.videoUrl;

  return (
    <div data-test="report-modal-title">
      {title || t('restrict:company_view.report.download_report')}
      {(videoUrl || videoUrlAlt) && (
        <ExplainFeatureButton deprecatedVideoUrl={videoUrlAlt || videoUrl} data-test="info-video" />
      )}
    </div>
  );
};

export default ReportsDownloadTitle;
