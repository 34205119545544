import gql from 'graphql-tag';

export const MARKET_OPPORTUNITY_FOLLOW = gql`
  mutation MARKET_OPPORTUNITY_FOLLOW($_id: ID!, $policiesPreset: String!) {
    marketOpportunityFollow(_id: $_id, policiesPreset: $policiesPreset) {
      __typename
      _id
      follow
    }
  }
`;

export const MARKET_OPPORTUNITY_UNFOLLOW = gql`
  mutation MARKET_OPPORTUNITY_UNFOLLOW($_id: ID!) {
    marketOpportunityUnfollow(_id: $_id) {
      __typename
      _id
      follow
    }
  }
`;

export const MARKET_OPPORTUNITY_REMOVE = gql`
  mutation MARKET_OPPORTUNITY_REMOVE($_id: ID!) {
    marketOpportunityRemove(_id: $_id)
  }
`;

export const MARKET_OPPORTUNITY_RESTORE = gql`
  mutation MARKET_OPPORTUNITY_RESTORE($_id: ID!) {
    marketOpportunityRestore(_id: $_id) {
      __typename
      _id
      covered
      follow
      job {
        _id
        trendIcon
        name
      }
    }
  }
`;
