import React, { Component, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Button, IconButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import { SnackbarProvider, withSnackbar } from 'notistack';
import PropTypes from 'prop-types';

const MessageContext = React.createContext();

const styles = theme => {
  const defaultStyle = {
    ...get(theme, 'customElements.snackBar.default', {}),
    flexFlow: 'row !important',
  };

  return {
    default: defaultStyle,
    success: defaultStyle,
    error: defaultStyle,
    warning: defaultStyle,
    info: defaultStyle,
  };
};

/* eslint-disable react/no-unused-state */

class Message extends Component {
  setMessage = ({
    text,
    type = 'info',
    undo = undefined,
    data,
    position = { vertical: 'top', horizontal: 'center' },
  }) => {
    const { enqueueSnackbar, t } = this.props;

    const action = [
      <IconButton
        key="close"
        aria-label={t('common:close')}
        style={{ color: '#fff' }}
        data-test="message-snackbar-close-button"
        size="large"
      >
        <CloseIcon />
      </IconButton>,
    ];

    if (undo) {
      action.push(
        <Button key="undo" sx={{ color: '#fff', whiteSpace: 'nowrap' }} size="small" onClick={undo}>
          {t('common:undo')}
        </Button>
      );
    }

    const messageText = isFunction(text) ? text(t, data) : text;

    if (messageText) {
      setTimeout(enqueueSnackbar, 0, messageText, {
        variant: type,
        action: action.reverse(),
        anchorOrigin: position,
      });
    }
  };

  render() {
    const { children } = this.props;

    return (
      <MessageContext.Provider
        value={{
          state: this.state,
          setMessage: this.setMessage,
        }}
      >
        {children}
      </MessageContext.Provider>
    );
  }
}

Message.propTypes = {
  children: PropTypes.node.isRequired,
};

const MessageProviderWithSnackbar = withSnackbar(withTranslation(['common', 'restrict'])(Message));

class MessageProviderWrapper extends Component {
  snackbarRef = React.createRef();

  onWindowClick = () => {
    const snackbar = this.snackbarRef.current;
    snackbar.queue = [];
    snackbar.state.snacks.forEach(({ key }) => {
      snackbar.closeSnackbar(key);
    });
  };

  componentDidMount = () => {
    window.addEventListener('click', this.onWindowClick);
  };

  componentWillUnmount = () => {
    window.removeEventListener('click', this.onWindowClick);
  };

  render() {
    const { classes } = this.props;

    return (
      <SnackbarProvider
        ref={this.snackbarRef}
        maxSnack={1}
        hideIconVariant
        classes={{
          variantSuccess: classes.success,
          variantError: classes.error,
          variantWarning: classes.warning,
          variantInfo: classes.info,
        }}
        data-test="message-snackbar"
      >
        <MessageProviderWithSnackbar {...this.props} />
      </SnackbarProvider>
    );
  }
}

const MessageProvider = withStyles(styles)(MessageProviderWrapper);

function withMessage(Children) {
  return function WrappedComponent(props) {
    return (
      <MessageContext.Consumer>
        {data => <Children {...props} message={data} />}
      </MessageContext.Consumer>
    );
  };
}

export const useMessageContext = () => useContext(MessageContext);

export { MessageContext, MessageProvider, withMessage };
