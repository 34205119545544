import gql from 'graphql-tag';

const GET_ORGANIZATION_CAPABILITIES = gql`
  query GET_ORGANIZATION_CAPABILITIES(
    $perPage: Int
    $page: Int
    $sort: SortOrganizationCapabilityDefaultTypeEnum
    $filter: OrganizationCapabilityInputFilterType
  ) {
    organizationCapabilities(sort: $sort, perPage: $perPage, page: $page, filter: $filter) {
      count
      items {
        _id
        companyId
        name
        organizationId
        organization {
          _id
          name
        }
        deleted
        description
        ownerUserId
        owner {
          _id
          profile {
            firstName
            lastName
          }
        }
        createdAt
        updatedAt
        type
        selfDefined
        location
        levelOfEmployeeMin
        levelOfEmployeeMax
        jobLevel
        published
        publishedAt
        deletedAt
        lastEditedBy
        link
        language
        customLink
        lastEditedByUser {
          profile {
            lastName
            firstName
          }
        }
        editable
        contactEmail
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;

export const CAPABILITY_SKILLS_FIELDS = `
  _id
  capabilityId
  skillId
  skill {
    _id
    synonym(policiesPreset: $policiesPreset) {
      _id
      synonymId
      synonymString
      synonymLang
      synonymLevelDescriptions {
        _id
        level
        description
      }
      companySpecific {
        _id
        isLogoVisible
      }
    }
    trends {
      _id
      trendIcon
    }
  }
  level
  createdAt
  updatedAt
  origin
  deleted
  mustHave
  selfDefined
`;

const GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS = gql`
  query GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS(
    $capabilityId: ID!
    $policiesPreset: PoliciesPresetInput
  ) {
    capabilitySkillsList(capabilityId: $capabilityId) {
      ${CAPABILITY_SKILLS_FIELDS}
    }
  }
`;

const GET_CAPABILITY_CREATORS_FROM_ORGANIZATION = gql`
  query GET_CAPABILITY_CREATORS_FROM_ORGANIZATION($organizationId: ID) {
    viewer {
      _id
      organization(organizationId: $organizationId) {
        _id
        organization {
          _id
          capabilityCreators {
            _id
            profile {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

const CAPABILITIES_SEARCH = gql`
  query CAPABILITIES_SEARCH(
    $capaTypes: [CapabilityTypeEnum!]
    $search: String
    $limit: Int
    $published: Boolean
  ) {
    capabilitiesList(capaTypes: $capaTypes, search: $search, limit: $limit, published: $published) {
      _id
      name
      type
      isPublished
    }
  }
`;

const GET_TOP_MEMBER_REPORT = gql`
  mutation GET_TOP_MEMBER_REPORT($view: String!, $timeZone: String!) {
    topMemberReport(view: $view, timeZone: $timeZone)
  }
`;

export {
  CAPABILITIES_SEARCH,
  GET_CAPABILITY_CREATORS_FROM_ORGANIZATION,
  GET_ORGANIZATION_CAPABILITIES,
  GET_ORGANIZATION_CAPABILITY_SKILLS_RESOLVE_SYNONYMS,
  GET_TOP_MEMBER_REPORT,
};
