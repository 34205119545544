import { bgBG, deDE, enUS, esES, frFR, itIT, ptPT, trTR } from '@mui/material/locale';

const getMuiLocale = language => {
  const mapping = {
    en: enUS,
    de: deDE,
    fr: frFR,
    it: itIT,
    pt: ptPT,
    es: esES,
    tr: trTR,
    bg: bgBG,
  };

  if (mapping.hasOwnProperty(language)) {
    return mapping[language];
  }

  return mapping.en;
};

export default getMuiLocale;
