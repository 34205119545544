import { useMemo } from 'react';

import useStillValidFollowedSkills from '../SkillsFilterPanel/FollowedOpportunitiesSection/useStillValidSkills';
import useStillValidLearnSkills from '../SkillsFilterPanel/SkillsSection/useStillValidSkills';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

const useStillValidPersistedSelectedSkills = persistedSelectedSkills => {
  const {
    isLoading: isLearnLoading,
    stillValidSkills: stillValidLearnSkills,
  } = useStillValidLearnSkills(persistedSelectedSkills);

  const {
    isLoading: isFollowedLoading,
    stillValidSkills: stillValidFollowedSkills,
  } = useStillValidFollowedSkills(persistedSelectedSkills);

  const loadingFlags = [isLearnLoading, isFollowedLoading];
  const isLoading = loadingFlags.some(Boolean);

  const stillValidPersistedSelectedSkills = useMemo(() => {
    if (isLoading) {
      return null;
    }
    const stillValidSkillLists = [persistedSelectedSkills].filter(Boolean);
    const validSkillsWithDuplicates = flatten(stillValidSkillLists);
    return uniqBy(validSkillsWithDuplicates, 'skillId');
  }, [isLoading, stillValidLearnSkills, stillValidFollowedSkills]);

  return {
    isLoading,
    stillValidPersistedSelectedSkills,
  };
};

export default useStillValidPersistedSelectedSkills;
