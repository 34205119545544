import React, { useRef } from 'react';

import SplashScreen from '../../containers/SplashScreen';
import { useUserContext } from '../../contexts/user';
import { useUserOrganizationsContext } from '../../contexts/userOrganizations';

export const DataLoadingGuard = ({ children }) => {
  const isDataLoadedRef = useRef(false);
  const {
    state: { isUserBeingLoaded },
  } = useUserContext();
  const {
    state: {
      organization: { loading: isOrganizationLoading },
    },
  } = useUserOrganizationsContext();

  isDataLoadedRef.current =
    isDataLoadedRef.current || (!isOrganizationLoading && !isUserBeingLoaded);

  if (!isDataLoadedRef.current) {
    return <SplashScreen />;
  }

  return children;
};
