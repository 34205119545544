import { clientLogger } from 'UI/lib';

import gql from 'graphql-tag';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

export const marketOpportunityUpdate = ({ store, input, mutationData = {} }) => {
  if (!mutationData || !mutationData._id) {
    return null;
  }

  const fragment = gql`
    fragment MarketOpportunityFields on MarketOpportunity {
      __typename
      ${Object.keys(input).join(' ')}
    }
  `;

  const dataToWrite = pick(mutationData, Object.keys(input));

  store.writeFragment({
    id: `MarketOpportunity:${mutationData._id}`,
    fragment,
    data: {
      ...dataToWrite,
      __typename: 'MarketOpportunity',
    },
  });
};

export const marketOpportunityRemove = ({
  store,
  _id,
  opportunitiesPageQuery,
  isToUpdateOpportunities,
}) => {
  try {
    if (isToUpdateOpportunities) {
      const data = { ...store.readQuery(opportunitiesPageQuery) };
      const { items, count, pageInfo } = data.marketOpportunities;

      data.marketOpportunities = {
        __typename: 'MarketOpportunityPagination',
        count: count - 1,
        pageInfo: {
          ...pageInfo,
          itemCount: count - 1,
        },
        items: items.filter(i => i._id !== _id),
      };

      store.writeQuery({
        ...opportunitiesPageQuery,
        data,
      });
    }
  } catch (error) {
    clientLogger.error(error);
  }
};

export const marketOpportunityRestore = ({
  store,
  mutationData = {},
  opportunitiesPageQuery,
  deletedOpportunitiesPageQuery,
  isToUpdateOpportunities,
  isToUpdateDeletedOpportunities,
}) => {
  try {
    if (isToUpdateOpportunities) {
      const data = store.readQuery(opportunitiesPageQuery);

      const { items, count, pageInfo } = data.marketOpportunities;

      data.marketOpportunities = {
        __typename: 'MarketOpportunityPagination',
        count,
        pageInfo,
        items: uniqBy([mutationData, ...items], i => i._id).sort((a, b) => b.rank - a.rank),
      };

      store.writeQuery({
        ...opportunitiesPageQuery,
        data,
      });
    }

    if (isToUpdateDeletedOpportunities) {
      const deletedData = { ...store.readQuery(deletedOpportunitiesPageQuery) };
      const { userOpportunitiesDeleted } = deletedData || {};

      deletedData.userOpportunitiesDeleted = {
        __typename: 'UserOpportunityPagination',
        count: userOpportunitiesDeleted.count - 1,
        pageInfo: {
          ...userOpportunitiesDeleted.pageInfo,
          itemCount: userOpportunitiesDeleted.count - 1,
        },
        items: userOpportunitiesDeleted.items.filter(i => i._id !== mutationData._id),
      };

      store.writeQuery({
        ...deletedOpportunitiesPageQuery,
        data: deletedData,
      });
    }
  } catch (error) {
    clientLogger.error(error);
  }
};
