import { env } from '../../env';

const useConfig = () => {
  const { clientConfig = {} } = window;

  if (!clientConfig.cdnUrl) {
    clientConfig.cdnUrl = env.VITE_CDN_URL;
  }

  return clientConfig;
};

export default useConfig;
