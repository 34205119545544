import React, { lazy, useMemo, useState } from 'react';
import { MdInfoOutline } from 'react-icons/md';

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { DialogActions, DialogContent } from '@mui/material/';
import Button from '@mui/material/Button';

import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import SkillExpertise from 'UI/components/SkillExpertise';
import { useMessageContext } from 'UI/contexts';
import useConfig from 'UI/customHooks/useConfig';
import { useT } from 'UI/lib';
import useAutocompleteGlobalSearch from 'UI/pages/restrict/customerSpecificSkills/CustomizeSynonyms/hooks/useAutocompleteGlobalSearch';

import useUpdateCompanySynonyms from '../hooks/useUpdateCompanySynonyms';
import get from 'lodash/get';

const AutoComplete = lazy(() => import('UI/components/autocomplete/autocomplete'));

const UpdateCompanySynonymModal = ({ onFinish, data, id, name, language, synonymId }) => {
  const [searchSkillSynonym, setSearchSkillSynonym] = useState(name);
  const [selectSynonym, setSelectSynonym] = useState({ id: synonymId, language, name });

  const message = useMessageContext();
  const t = useT();
  const config = useConfig();

  const [form, setForm] = useState({
    ...data,
  });

  const { autoCompleteItems, onSelect, onClear, resetSearch } = useAutocompleteGlobalSearch({
    searchSkillSynonym,
    setSelectSynonym,
    setSearchSkillSynonym,
    setForm,
  });

  const supportedLevels = useMemo(() => get(config, 'cms.features.skills.expertiseLevels'), [
    config,
  ]);

  const disableSubmit = useMemo(() => {
    const { description, ...restForm } = form;
    return Object.entries(restForm).some(([, value]) => value === '' || value === null);
  }, [form]);

  const { updateCompanySpecific } = useUpdateCompanySynonyms({ form, id });

  const handleTextField = (e, name) => {
    setForm(prevState => ({ ...prevState, [name]: e.target.value }));
  };

  const handleLevelDescription = (e, level) => {
    setForm(prevState => ({
      ...prevState,
      levelDescriptions: {
        ...prevState.levelDescriptions,
        [level]: e.target.value,
      },
    }));
  };

  const handleSwitch = (e, name) => {
    setForm(prevState => ({ ...prevState, [name]: e.target.checked }));
  };

  const handleClose = () => {
    resetSearch();
    onFinish();
  };

  const onSubmit = e => {
    e.preventDefault();
    updateCompanySpecific()
      .then(() => {
        message.setMessage({
          text: t('restrict:company_view.customer_synonyms.default.dialog.updated'),
          type: 'success',
        });
        handleClose();
      })
      .catch(e => {
        if (e.message === 'CONFLICT') {
          return message.setMessage({
            text: t(
              'restrict:company_view.customer_synonyms.default.dialog.synonym_id_already_taken',
              {
                name: selectSynonym.name,
              }
            ),
            type: 'info',
          });
        }
        message.setMessage({
          text: t('common:default_error_message'),
          type: 'error',
        });
      });
  };

  return (
    <Box>
      <form onSubmit={onSubmit}>
        <DialogContent>
          <Grid container rowSpacing={8} sx={{ flexDirection: 'column' }}>
            <Grid item>
              <TextField
                variant="standard"
                maxRows={2}
                label={t('restrict:company_view.customer_synonyms.default.column.custom_name')}
                value={form.customName}
                onChange={e => handleTextField(e, 'customName')}
                data-test="modal-container-custom-name"
                InputLabelProps={{ 'data-test': 'modal-input-label-custom-name' }}
                inputProps={{ 'data-test': 'modal-input-custom-name' }}
              />
            </Grid>
            <Grid item>
              <FormControl data-test="skill-inventory-search">
                <LazyComponent>
                  <AutoComplete
                    id="search-skills-combobox"
                    autoFocus
                    placeholder={t(
                      'restrict:company_view.customer_synonyms.default.dialog.name_input_placeholder'
                    )}
                    items={autoCompleteItems}
                    onSearch={setSearchSkillSynonym}
                    delay={150}
                    fullWidth
                    clearInputOnSelect={false}
                    clearOnBlur
                    onSelect={onSelect}
                    selectedItem={selectSynonym || null}
                    onClear={onClear}
                    value={selectSynonym}
                    isOptionEqualToValue={(option, value) => {
                      return (
                        option.synonymId === value.id ||
                        option.synonymId === value.synonymId ||
                        value === ''
                      );
                    }}
                  />
                </LazyComponent>
              </FormControl>
            </Grid>
            <Grid
              sx={{
                paddingTop: 2,
                display: 'flex',
              }}
            >
              <MdInfoOutline color="#8A8A8A" />
              <Typography sx={{ color: '#8A8A8A', fontSize: 12, marginRight: 2 }}>
                {t('restrict:company_view.customer_synonyms.default.column.language')}:
              </Typography>
              <Typography sx={{ textTransform: 'uppercase', fontSize: 12 }}>
                {selectSynonym?.language}
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                variant="standard"
                value={form.description}
                onChange={e => handleTextField(e, 'description')}
                multiline
                maxRows={3}
                label={
                  <Typography>
                    {t('restrict:company_view.customer_synonyms.default.dialog.write_description')}:
                  </Typography>
                }
                placeholder={t(
                  'restrict:company_view.customer_synonyms.default.dialog.input_placeholder'
                )}
                sx={{
                  textarea: {
                    '&::-webkit-scrollbar': { width: 7 },
                    '&::-webkit-scrollbar-track': {
                      backgroundColor: '#D9D9D9',
                      borderRadius: 100,
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: '#808080',
                      borderRadius: 100,
                    },
                    scrollbarGutter: 'stable',
                  },
                }}
                data-test="modal-div-description"
                inputProps={{ 'data-test': 'modal-textarea-description' }}
              />
            </Grid>
            {Object.entries(form?.levelDescriptions).map(([key, value], index) => {
              if (key === 'SuperExpert' && supportedLevels.length !== 4) {
                return null;
              }
              return (
                <Grid item key={`${key}-modal-input`}>
                  <TextField
                    variant="standard"
                    multiline
                    maxRows={3}
                    label={
                      <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingBottom: 4 }}>
                        <SkillExpertise level={key} disableTitleText />{' '}
                        <Typography>
                          (
                          {t('restrict:company_view.customer_synonyms.default.dialog.star_info', {
                            number: index + 1,
                          })}
                          ):
                        </Typography>
                      </Box>
                    }
                    placeholder={t(
                      'restrict:company_view.customer_synonyms.default.dialog.input_placeholder'
                    )}
                    sx={{
                      textarea: {
                        '&::-webkit-scrollbar': { width: 7 },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: '#D9D9D9',
                          borderRadius: 100,
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#808080',
                          borderRadius: 100,
                        },
                        scrollbarGutter: 'stable',
                      },
                    }}
                    value={value || ''}
                    onChange={e => handleLevelDescription(e, key)}
                    data-test={`modal-div-description-${key}`}
                    inputProps={{ 'data-test': `modal-textarea-description-${key}` }}
                  />
                </Grid>
              );
            })}
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={form.isLogoVisible}
                    data-test="modal-switch-logo"
                    onChange={e => handleSwitch(e, 'isLogoVisible')}
                  />
                }
                label={t('restrict:company_view.customer_synonyms.default.dialog.logo_input_label')}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            sx={{ textTransform: 'uppercase' }}
            onClick={() => handleClose()}
          >
            {t('common:cancel')}
          </Button>
          <Button
            variant="contained"
            type="submit"
            sx={{ textTransform: 'uppercase' }}
            disabled={disableSubmit}
          >
            {t('common:submit')}
          </Button>
        </DialogActions>
      </form>
    </Box>
  );
};

export default UpdateCompanySynonymModal;
