import { useCallback, useEffect, useState } from 'react';

import useConfig from 'UI/customHooks/useConfig';
import { useT } from 'UI/lib';
import { trackEvent } from 'UI/lib/googleAnalytics';

import usePersistedFiltersLxp from '../../graphql/usePersistedFiltersLxp';
import usePersistSelectedFilterBar from './../../graphql/usePersistSelectedFiltersLxp';
import useStillValidPersistedSelectedSkills from './../../hooks/useStillValidPersistedSelectedSkills';
import get from 'lodash/get';

const useSelectedSkills = () => {
  const config = useConfig();
  const t = useT();
  const maxSkillCount = get(config, 'cms.features.personalDevelopment.selectedSkillsLimit');

  const { persistedSelectedSkills, isPromoted, learningType } = usePersistedFiltersLxp();

  const { persistSelectedFilterBar, loading } = usePersistSelectedFilterBar();

  const { stillValidPersistedSelectedSkills, isLoading } = useStillValidPersistedSelectedSkills(
    persistedSelectedSkills
  );
  const [selectedSkills, setSelectedSkills] = useState(stillValidPersistedSelectedSkills || []);
  const [debouncedPersistSelectedSkills, setDebouncedPersistSelectedSkills] = useState([]);

  const isMaxLimitExceeded = selectedSkills && selectedSkills.length >= maxSkillCount;
  const skillLimitReached = isMaxLimitExceeded ? maxSkillCount : undefined;

  useEffect(() => {
    if (!isLoading) {
      setDebouncedPersistSelectedSkills(stillValidPersistedSelectedSkills);
      setSelectedSkills(stillValidPersistedSelectedSkills);
    }
  }, [isLoading, stillValidPersistedSelectedSkills]);

  const onResetFilterConfirmation = useCallback(() => {
    trackEvent({
      category: 'filter',
      action: 'reset',
      label: 'learningV2',
    });
    if (window.confirm(t('restrict:learn_page.skills_filter_panel_reset_all_confirm'))) {
      setSelectedSkills([]);
      persistSelectedFilterBar({ skills: [] });
      setDebouncedPersistSelectedSkills([]);
      // resetAllFilters();
    }
  }, [setSelectedSkills, persistSelectedFilterBar, t, setDebouncedPersistSelectedSkills]);

  const handleSelectedSkill = useCallback(
    skill => {
      const newSelectedSkill = {
        skillId: skill.skillId,
        synonymId: skill.synonymId,
      };

      const existingSelectedSkill = selectedSkills.find(
        selectedSkill => selectedSkill.skillId === newSelectedSkill.skillId
      );

      let skillsToUpdate;
      if (existingSelectedSkill) {
        skillsToUpdate = selectedSkills.filter(
          selectedSkill => selectedSkill.skillId !== newSelectedSkill.skillId
        );
      } else {
        if (isMaxLimitExceeded) {
          return false;
        }
        skillsToUpdate = [...selectedSkills, newSelectedSkill];
      }

      setSelectedSkills(skillsToUpdate);
      // save in db
      persistSelectedFilterBar({
        skills: skillsToUpdate,
        promoted: isPromoted,
        learningType,
      });
    },
    [isPromoted, learningType, selectedSkills, persistSelectedFilterBar, isMaxLimitExceeded]
  );

  return {
    selectedSkills: debouncedPersistSelectedSkills,
    handleSelectedSkill,
    skillLimitReached,
    onResetFilterConfirmation,
    isLoading: loading,
  };
};

export default useSelectedSkills;
