import { useUserContext } from '../contexts/user';

export const useAuthUserDetails = () => {
  const { state: userState } = useUserContext();
  const { currentUser, isUserBeingLoaded } = userState;
  const userId = currentUser ? currentUser._id : null;
  const isSsoUser = Boolean(currentUser && currentUser.isSsoUser);
  const hasOnboarded = Boolean(currentUser && currentUser.hasOnboarded);
  const isAuthenticated = Boolean(currentUser && currentUser._id);
  const hasAccessToRestrictedPages = Boolean(isAuthenticated);

  return {
    hasAccessToRestrictedPages,
    userId,
    isUserBeingLoaded,
    isSsoUser,
    hasOnboarded,
    isAuthenticated,
  };
};
