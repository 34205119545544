import React, { lazy, useEffect } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import {
  adaptV4Theme,
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material/styles';

import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import {
  GlobalSearchProvider,
  MessageProvider,
  OrganizationCapabilitiesProvider,
} from 'UI/contexts';
import { CompanyOpportunitiesProvider } from 'UI/contexts/companyOpportunities';
import { MarketOpportunitiesProvider } from 'UI/contexts/marketOpportunities';
import { ModalsProvider } from 'UI/contexts/modals';
import { useUserContext } from 'UI/contexts/user';
import { UserSkillsProvider } from 'UI/contexts/userSkills';
import { UserValidateSkillsProvider } from 'UI/contexts/userValidateSkills';
import useConfig from 'UI/customHooks/useConfig';
import { getFullCdnUrl } from 'UI/lib/getUrlType';

import { FilterExpandToggleProvider } from '../../contexts/filterExpandToggle';
import { LearningLxpFiltersProvider } from '../../contexts/learningLxpFilters';
import { LearningV2FiltersProvider } from '../../contexts/learningV2Filters';
import { UserOrganizationsProvider } from '../../contexts/userOrganizations';
import {
  init as initGa,
  setClientName as setClientNameForGa,
  setUserId as setUserIdForGa,
  trackPageview,
} from '../../lib/googleAnalytics';
import getMuiLocale from '../../lib/mapMuiLocaleToLanguage';
import { DataLoadingGuard } from '../data-loading-guard';
import defaultTheme from './theme_default';
import get from 'lodash/get';
import PropTypes from 'prop-types';

const LocalizationWrapper = lazy(() => import('../localization'));

const helmetDefaultProps = ({ pageTitle, favicon, theme, cdnUrl }) => {
  return {
    titleTemplate: `%s - ${pageTitle}`,
    meta: [
      { charset: 'UTF-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'author', content: `${pageTitle}` },
      { name: 'robots', content: 'noindex' },
      { name: 'theme-color', content: theme.palette.custom.secondary },
      { name: 'msapplication-TileColor', content: theme.palette.custom.secondary },
      { name: 'msapplication-config', content: `${cdnUrl}/assets/favicons/browserconfig.xml` },
    ],
    link: [
      {
        rel: 'icon',
        type: 'image/png',
        sizes: '16x16',
        href: favicon,
      },
    ],
  };
};

const RootWrapper = ({ children }) => {
  const { i18n } = useTranslation();
  const { GA } = useConfig();
  const pegasusConfig = useConfig();
  const { hasGoogleAnalytics } = get(pegasusConfig, 'cms.features', false);
  const { name, theme: whiteLabellingTheme, pageTitle, favicon } = PALYX_WHITE_LABEL;
  const history = useHistory();
  const userContext = useUserContext();
  const currentUserId = userContext.state.currentUser?._id;
  const { cdnUrl } = pegasusConfig;

  useEffect(() => {
    if (GA && Boolean(hasGoogleAnalytics)) {
      initGa(GA);
      setClientNameForGa(name);
      trackPageview(history.location.pathname + history.location.search);
    }
  }, [GA, hasGoogleAnalytics]);

  useEffect(() => {
    setUserIdForGa(currentUserId);
  }, [currentUserId]);

  useEffect(() => {
    return history.listen(location => {
      trackPageview(location.pathname + location.search);
    });
  }, [history]);

  const muiLocale = getMuiLocale(i18n.language);
  const currentTheme = createTheme(adaptV4Theme(whiteLabellingTheme), muiLocale);

  return (
    <>
      <Helmet
        defer={false}
        {...helmetDefaultProps({
          pageTitle,
          favicon: getFullCdnUrl(favicon, cdnUrl),
          theme: defaultTheme,
          cdnUrl,
        })}
        htmlAttributes={{ lang: i18n.language || 'en' }}
      />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={currentTheme}>
          <LazyComponent>
            <LocalizationWrapper fallback={<Box />}>
              <MessageProvider>
                <UserOrganizationsProvider>
                  <OrganizationCapabilitiesProvider>
                    <UserSkillsProvider>
                      <UserValidateSkillsProvider>
                        <MarketOpportunitiesProvider>
                          <CompanyOpportunitiesProvider>
                            <GlobalSearchProvider>
                              <ModalsProvider>
                                <FilterExpandToggleProvider>
                                  <LearningV2FiltersProvider>
                                    <LearningLxpFiltersProvider>
                                      <CssBaseline />
                                      <DataLoadingGuard>{children}</DataLoadingGuard>
                                    </LearningLxpFiltersProvider>
                                  </LearningV2FiltersProvider>
                                </FilterExpandToggleProvider>
                              </ModalsProvider>
                            </GlobalSearchProvider>
                          </CompanyOpportunitiesProvider>
                        </MarketOpportunitiesProvider>
                      </UserValidateSkillsProvider>
                    </UserSkillsProvider>
                  </OrganizationCapabilitiesProvider>
                </UserOrganizationsProvider>
              </MessageProvider>
            </LocalizationWrapper>
          </LazyComponent>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

RootWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default RootWrapper;
