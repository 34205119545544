import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { useUserContext } from 'UI/contexts/user';
import { clientLogger } from 'UI/lib';

import { GET_VIEWER_SKILLS } from './getViewerSkills.query';

const useViewerSkills = ({ skillSynonymPoliciesPreset, skip }) => {
  const {
    state: { currentUser },
  } = useUserContext();

  const skillBadgesMap = useMemo(
    () =>
      currentUser?.badges?.reduce((acc, badge) => {
        for (const synonymId of badge.skillIds) {
          if (!acc[synonymId]) {
            acc[synonymId] = [];
          }
          acc[synonymId].push(badge);
        }
        return acc;
      }, {}),
    [currentUser]
  );

  const { data, loading, error, refetch } = useQuery(GET_VIEWER_SKILLS, {
    variables: {
      policiesPreset: { preset: skillSynonymPoliciesPreset },
    },
    errorPolicy: 'all',
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip,
  });

  useEffect(() => {
    if (error) {
      clientLogger.error(`useViewerSkills - ${error.message}`, error);
    }
  }, [error]);

  const skills = useMemo(() => {
    if (!data) {
      return [];
    }

    const { viewerSkills } = data;
    const sks = [];

    for (const userSkill of viewerSkills) {
      if (!userSkill.skill?.synonym) {
        continue;
      }

      sks.push({
        ...userSkill,
        badges: skillBadgesMap?.[userSkill.skill.synonym._id] || null,
      });
    }

    return sks;
  }, [data, skillBadgesMap]);

  return {
    skills,
    isLoading: loading,
    hasError: Boolean(error),
    // when the FT is enabled, the value is coming as an empty array, at least.
    hasBadges: Boolean(currentUser?.badges),
    refetch,
  };
};

export default useViewerSkills;
