import { CompanyOpportunityFields } from './CompanyOpportunityFields.fragment';
import gql from 'graphql-tag';

export const GET_COMPANY_OPPORTUNITIES = gql`
  ${CompanyOpportunityFields}

  query GET_COMPANY_OPPORTUNITIES(
    $perPage: Int
    $page: Int
    $sortDirection: String
    $sortBy: String
    $filter: CompanyOpportunityFilterInputType
  ) {
    companyOpportunities(
      perPage: $perPage
      page: $page
      sortBy: $sortBy
      sortDirection: $sortDirection
      filter: $filter
    ) {
      __typename
      count
      items {
        description
        contactEmail
        ...CompanyOpportunityFields
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;
