import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import { clientLogger } from 'UI/lib';

import { getBrowserUserCredentials } from '../../../../../../auth/userCredentials';
import { GET_COURSE_OPPORTUNITIES_V2 } from './queries';
import get from 'lodash/get';

const allowedLocations = ['/app/personal-development/recommendations', '/app/learn-v2/course'];

const useCourseOpportunities = () => {
  const { palyxToken } = getBrowserUserCredentials();
  const config = useConfig();
  const location = useLocation();
  const hasLXPLearnFeature =
    config.cms?.pages?.personalDevelopment?.recommendations?.courses?.source;
  const isInAllowedLocation = useMemo(() => {
    return allowedLocations.includes(location.pathname);
  }, [location.pathname]);
  const { loading, data, error: queryError } = useQuery(GET_COURSE_OPPORTUNITIES_V2, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !palyxToken || hasLXPLearnFeature || !isInAllowedLocation,
  });

  useEffect(() => {
    if (queryError) {
      clientLogger.error(`useCourseOpportunities - ${queryError.message}`, queryError);
    }
  }, [queryError]);

  return {
    loading,
    data: get(data, 'coursesOpportunities'),
    error: queryError,
  };
};

export default useCourseOpportunities;
