import { useEffect, useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { clientLogger } from 'UI/lib';

import gql from 'graphql-tag';

export const useOpportunitySkillSynonyms = ({
  opportunityId,
  skillIds,
  skip,
  skillSynonymPoliciesPreset,
}) => {
  useEffect(() => {
    if (skillIds) {
      skillIds.sort();
    }
  }, [skillIds]);

  const { data, loading, error: queryError } = useQuery(
    gql`
      query GET_SKILL_SYNONYMS_FOR_OPPORTUNITY(
        $skillIds: [ID!]!
        $policiesPreset: PoliciesPresetInput
      ) {
        skillByIds(skillIds: $skillIds) {
          _id
          synonym(policiesPreset: $policiesPreset) {
            _id
            synonymId
            synonymString
            synonymLang
            companySpecific {
              _id
              isLogoVisible
            }
          }
        }
      }
    `,
    {
      variables: {
        policiesPreset: {
          preset: skillSynonymPoliciesPreset,
          params: {
            capabilityId: opportunityId,
          },
        },
        skillIds,
      },
      skip: skip || !skillIds?.length,
    }
  );

  const error = queryError;

  useEffect(() => {
    if (error) {
      clientLogger.error(`useOpportunitySkillSynonyms - ${error.message}`, error);
    }
  }, [error]);

  const skillsWithSynonyns = useMemo(() => data?.skillByIds?.filter(skill => skill?.synonym), [
    data,
  ]);

  return {
    skills: skillsWithSynonyns,
    isLoading: loading,
    hasError: Boolean(error),
  };
};
