import { useCallback, useEffect, useState } from 'react';

import useConfig from 'UI/customHooks/useConfig';
import { useT } from 'UI/lib';
import { trackEvent } from 'UI/lib/googleAnalytics';

import usePersistedFilters2 from '../../graphql/usePersistedFilters2';
import usePersistSelectedFilterBar from '../../graphql/usePersistSelectedFilterBar';
import useStillValidPersistedSelectedSkills from './../../hooks/useStillValidPersistedSelectedSkills';
import get from 'lodash/get';

const useSelectedSkills = () => {
  const config = useConfig();
  const t = useT();
  const maxSkillCount = get(config, 'cms.features.personalDevelopment.selectedSkillsLimit');

  const {
    persistedSelectedSkills,
    persistedSelectedHasAttended,
    persistedSelectedLanguages,
    persistedSelectedSourceIds,
    persistedSelectedKeywords,
  } = usePersistedFilters2();

  const { persistSelectedFilterBar } = usePersistSelectedFilterBar();

  const { stillValidPersistedSelectedSkills, isLoading } = useStillValidPersistedSelectedSkills(
    persistedSelectedSkills
  );
  const [selectedSkills, setSelectedSkills] = useState(stillValidPersistedSelectedSkills || []);
  const [debouncedPersistSelectedSkills, setDebouncedPersistSelectedSkills] = useState([]);

  const isMaxLimitExceeded = selectedSkills && selectedSkills.length >= maxSkillCount;
  const skillLimitReached = isMaxLimitExceeded ? maxSkillCount : undefined;

  useEffect(() => {
    if (!isLoading) {
      setDebouncedPersistSelectedSkills(stillValidPersistedSelectedSkills);
      setSelectedSkills(stillValidPersistedSelectedSkills);
    }
  }, [isLoading, stillValidPersistedSelectedSkills]);

  const onResetFilterConfirmation = useCallback(() => {
    trackEvent({
      category: 'filter',
      action: 'reset',
      label: 'learningV2',
    });
    if (window.confirm(t('restrict:learn_page.skills_filter_panel_reset_all_confirm'))) {
      setSelectedSkills([]);
      persistSelectedFilterBar({ skills: [], keywords: [], sourceIds: [], languages: [] });
      setDebouncedPersistSelectedSkills([]);
    }
  }, [setSelectedSkills, persistSelectedFilterBar, t, setDebouncedPersistSelectedSkills]);

  const handleSelectedSkill = useCallback(
    skill => {
      const newSelectedSkill = {
        skillId: skill.skillId,
        synonymId: skill.synonymId,
      };

      const existingSelectedSkill = selectedSkills.find(
        selectedSkill => selectedSkill.skillId === newSelectedSkill.skillId
      );

      let skillsToUpdate;
      if (existingSelectedSkill) {
        skillsToUpdate = selectedSkills.filter(
          selectedSkill => selectedSkill.skillId !== newSelectedSkill.skillId
        );
      } else {
        if (isMaxLimitExceeded) {
          return false;
        }
        skillsToUpdate = [...selectedSkills, newSelectedSkill];
      }

      setSelectedSkills(skillsToUpdate);
      // save in db
      persistSelectedFilterBar({
        skills: skillsToUpdate,
        languages: persistedSelectedLanguages,
        hasAttendedOnly: persistedSelectedHasAttended,
        sourceIds: persistedSelectedSourceIds,
        keywords: persistedSelectedKeywords,
      });
    },
    [
      selectedSkills,
      persistSelectedFilterBar,
      persistedSelectedLanguages,
      persistedSelectedHasAttended,
      persistedSelectedSourceIds,
      persistedSelectedKeywords,
      isMaxLimitExceeded,
    ]
  );

  return {
    selectedSkills: debouncedPersistSelectedSkills,
    handleSelectedSkill,
    skillLimitReached,
    onResetFilterConfirmation,
  };
};

export default useSelectedSkills;
