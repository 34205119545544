import { useCallback, useEffect } from 'react';

import { gql, useMutation } from '@apollo/client';

import { clientLogger } from 'UI/lib';
import { GET_COURSES_USER_PERSISTED_FILTERS_LXP } from 'UI/pages/restrict/learn-lxp/Course/graphql/queries';

import omitBy from 'lodash/omitBy';

const usePersistSelectedFiltersLxp = () => {
  const [persistSelectedFilterBar, { loading, data, error }] = useMutation(
    gql`
      mutation PERSIST_COURSES_USER_FILTERS_LXP(
        $learningType: [ID]
        $promoted: Boolean
        $skills: [CoursesSkillSynonymPairInputLxp]
      ) {
        coursesPersistFiltersLxp(learningType: $learningType, promoted: $promoted, skills: $skills)
      }
    `
  );

  useEffect(() => {
    if (error) {
      clientLogger.error(`usePersistSelectedFilterBar - ${error.message}`, error);
    }
  }, [error]);

  return {
    persistSelectedFilterBar: useCallback(
      ({ promoted = null, learningType = null, skills = null }) => {
        persistSelectedFilterBar({
          variables: omitBy(
            {
              promoted,
              learningType,
              skills,
            },
            value => value === null
          ),
          optimisticResponse: {
            coursesPersistFiltersLxp: true,
          },
          update: cache => {
            cache.writeQuery({
              query: GET_COURSES_USER_PERSISTED_FILTERS_LXP,
              data: {
                coursesPersistedFiltersLxp: {
                  learningType,
                  promoted,
                  skills,
                },
              },
            });
          },
        });
      },
      [persistSelectedFilterBar]
    ),
    loading,
    data,
    error,
  };
};

export default usePersistSelectedFiltersLxp;
