const styles = theme => ({
  buttonWrapper: {
    marginTop: 20,
  },
  // "hack" to increase inside height with editor area still active
  textEditor: {
    '& .public-DraftEditor-content': {
      minHeight: 150,
    },
  },
  loading: {
    padding: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.custom.twentyOne,
  },
});

export default styles;
