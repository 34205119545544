import { env } from './env';

const loadConfig = async configFile => {
  const { VITE_BACKEND_URL, VITE_VERSION, VITE_SIMULATE_TENANT } = env;
  const configUrl = `${VITE_BACKEND_URL}/${configFile}?ver=${VITE_VERSION}`;
  const headers = {};
  if (VITE_SIMULATE_TENANT) {
    headers['dev-simulate-tenant'] = VITE_SIMULATE_TENANT;
  }
  const response = await fetch(configUrl, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`Failed to fetch ${configFile}: ${response.status}`);
  }

  return response.json();
};

const loadTenantConfig = async () => {
  const [whiteLabel, clientConfig] = await Promise.all([
    loadConfig('whiteLabel.js'),
    loadConfig('clientConfig.js'),
  ]);

  window.clientConfig = clientConfig;
  window.PALYX_WHITE_LABEL = whiteLabel;
  window.rootOrganizationId = clientConfig?.cms?.organizationId;
};

export default loadTenantConfig;
