import gql from 'graphql-tag';

const GET_ORGANIZATION_MEMBERS = gql`
  query GET_ORGANIZATION_MEMBERS(
    $perPage: Int
    $page: Int
    $sort: SortUserOrganizationDefaultTypeEnum
    $filter: UserOrganizationInputFilterType
  ) {
    organizationEmployees(perPage: $perPage, page: $page, sort: $sort, filter: $filter) {
      count
      items {
        _id
        userId
        organizationId
        createdAt
        user {
          _id
          canBeMSAManaged
          profile {
            name
            avatar
            email
            nationality {
              name
            }
            workPermit {
              name
            }
          }
          skills {
            managerAssessment {
              status
            }
          }
          createdAt
          lastLogin
          organizations {
            _id
            roles
            organizationId
            userId
            organization {
              _id
              name
            }
          }
          isUserProfileVisible
        }
      }
      pageInfo {
        pageCount
        itemCount
        hasNextPage
        hasPreviousPage
        currentPage
        perPage
      }
    }
  }
`;

const GET_VIEWER_ORGANIZATION_MOBILITY_SCORE = gql`
  query GET_VIEWER_ORGANIZATION_MOBILITY_SCORE($organizationId: ID) {
    viewer {
      _id
      organization(organizationId: $organizationId) {
        _id
        mobilityCalculatorScore
      }
    }
  }
`;

const GET_JOB_LEVELS = gql`
  query GET_COMPANY_JOB_LEVELS($organizationId: ID) {
    viewer {
      _id
      organization(organizationId: $organizationId) {
        _id
        organization {
          _id
          jobLevels {
            company
            jobLevel
            name
          }
        }
      }
    }
  }
`;

const GET_FILTER_ORGANIZATION_NAME = gql`
  query GET_FILTER_ORGANIZATION_NAME($organizationIds: [ID!]!) {
    organizations(organizationIds: $organizationIds) {
      _id
      name
    }
  }
`;

const GET_FILTER_ORGANIZATION_LIST = gql`
  query GET_FILTER_ORGANIZATION_LIST($restrict: Boolean, $includeAncestor: Boolean) {
    organizationHierarchy(restrict: $restrict, includeAncestor: $includeAncestor)
  }
`;

export {
  GET_FILTER_ORGANIZATION_LIST,
  GET_FILTER_ORGANIZATION_NAME,
  GET_JOB_LEVELS,
  GET_ORGANIZATION_MEMBERS,
  GET_VIEWER_ORGANIZATION_MOBILITY_SCORE,
};
