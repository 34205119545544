import React, { Component, useContext } from 'react';

import { clientLogger } from 'UI/lib';

import getApolloClient from '../../getApolloClient';
import {
  companyOpportunityRemove,
  companyOpportunityRestore,
  companyOpportunityUpdate,
} from '../apolloStorage/companyOpportunities';
import {
  COMPANY_OPPORTUNITY_FOLLOW,
  COMPANY_OPPORTUNITY_REMOVE,
  COMPANY_OPPORTUNITY_RESTORE,
  COMPANY_OPPORTUNITY_TOOGLE_TOP_MEMBERS_VISIBILITY,
  COMPANY_OPPORTUNITY_UNFOLLOW,
} from '../mutations/companyOpportunities';
import { GET_COMPANY_OPPORTUNITIES } from '../pages/restrict/opportunities/CompanyOpportunities/companyOpportunities.query';
import { GET_VIEWER_DELETED_OPPORTUNITIES } from '../queries/userOpportunities';
import { withMessage } from '.';
import { withUser } from './user';
import PropTypes from 'prop-types';

const CompanyOpportunitiesContext = React.createContext();

const generateDefaultOptimisticResponseObject = ({ method, input, _id }) => ({
  __typename: 'Mutation',
  [method]: {
    __typename: 'CompanyOpportunity',
    _id,
    ...input,
  },
});

const mutationReducer = ({ action, payload = {}, details = {} }) => {
  const { _id, input } = payload;
  const { optimisticItem = {}, opportunity = {} } = details;

  const refetchQueries = [];

  let mutation;
  let method;
  let optmisticMethod;
  let optimisticResponse;

  const opportunitiesPageQuery = [GET_COMPANY_OPPORTUNITIES];
  const deletedOpportunitiesPageQuery = [GET_VIEWER_DELETED_OPPORTUNITIES];

  const isOpportunityPage = window.location.pathname.indexOf('/opportunities/company') > -1;
  const isDeletedOpportunityPage = window.location.pathname.indexOf('/opportunities/deleted') > -1;

  const isToUpdateOpportunities = opportunitiesPageQuery && isOpportunityPage;
  const isToUpdateDeletedOpportunities = deletedOpportunitiesPageQuery && isDeletedOpportunityPage;

  switch (action) {
    case 'follow':
      mutation = COMPANY_OPPORTUNITY_FOLLOW;
      method = 'companyOpportunityFollow';
      optmisticMethod = companyOpportunityUpdate;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
        input,
      });
      break;
    case 'unfollow':
      mutation = COMPANY_OPPORTUNITY_UNFOLLOW;
      method = 'companyOpportunityUnfollow';
      optmisticMethod = companyOpportunityUpdate;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
        input,
      });
      break;
    case 'remove':
      mutation = COMPANY_OPPORTUNITY_REMOVE;
      method = 'companyOpportunityRemove';
      optmisticMethod = companyOpportunityRemove;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
      });
      optimisticResponse[method] = true;
      if (isToUpdateDeletedOpportunities) {
        refetchQueries.push(deletedOpportunitiesPageQuery);
      }
      break;
    case 'restore':
      mutation = COMPANY_OPPORTUNITY_RESTORE;
      method = 'companyOpportunityRestore';
      optmisticMethod = companyOpportunityRestore;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
      });
      optimisticResponse[method] = {
        ...optimisticResponse[method],
        _id: `loading-${_id}`,
        covered: 0,
        follow: false,
        job: {
          __typename: 'CompanyJob',
          _id,
          trendIcon: null,
          name: null,
        },
        missing: null,
        matching: null,
        ...optimisticItem,
      };
      if (isToUpdateOpportunities) {
        refetchQueries.push(opportunitiesPageQuery);
      }
      if (isToUpdateDeletedOpportunities) {
        refetchQueries.push(deletedOpportunitiesPageQuery);
      }
      break;

    case 'toogleVisibility':
      mutation = COMPANY_OPPORTUNITY_TOOGLE_TOP_MEMBERS_VISIBILITY;
      method = 'companyOpportunityTopMembersToogleVisibility';
      optmisticMethod = companyOpportunityUpdate;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
        input,
      });
      break;

    default:
      break;
  }

  const variables = {
    _id,
  };

  if (action === 'follow') {
    variables.policiesPreset =
      opportunity.__typename === 'MarketOpportunity' ? 'default' : 'capabilitySkills';
  }

  if (action === 'toogleVisibility') {
    variables.isAnonymous = input.isAnonymous;
    if (input.eventId) {
      variables.eventId = input.eventId;
    }
  }

  return getApolloClient().mutate({
    mutation,
    variables,
    optimisticResponse,
    refetchQueries,
    update: (store, { data }) => {
      const mutationData = data[method];

      try {
        optmisticMethod({
          store,
          _id,
          input,
          mutationData,
          opportunitiesPageQuery,
          deletedOpportunitiesPageQuery,
          isToUpdateOpportunities,
          isToUpdateDeletedOpportunities,
        });
      } catch (error) {
        clientLogger.error(error);
      }
    },
  });
};

/* eslint-disable react/no-unused-state */

class CompanyOpportunities extends Component {
  state = {};

  follow = (_id, details) =>
    mutationReducer({
      action: 'follow',
      payload: {
        _id,
        input: {
          follow: true,
        },
      },
      details,
    });

  unfollow = _id =>
    mutationReducer({
      action: 'unfollow',
      payload: {
        _id,
        input: {
          follow: false,
        },
      },
    });

  toogleVisibility = (_id, isAnonymous, eventId) =>
    mutationReducer({
      action: 'toogleVisibility',
      payload: {
        _id,
        input: {
          isAnonymous: !isAnonymous,
          eventId,
        },
      },
    });

  remove = (_id, details) =>
    mutationReducer({
      action: 'remove',
      payload: {
        _id,
      },
      details,
    });

  restore = (_id, details) =>
    mutationReducer({
      action: 'restore',
      payload: {
        _id,
      },
      details,
    });

  render() {
    const { state } = this;
    const { children } = this.props;

    return (
      <CompanyOpportunitiesContext.Provider
        value={{
          state,
          follow: this.follow,
          unfollow: this.unfollow,
          toogleVisibility: this.toogleVisibility,
          remove: this.remove,
          restore: this.restore,
        }}
      >
        {children}
      </CompanyOpportunitiesContext.Provider>
    );
  }
}

CompanyOpportunities.propTypes = {
  children: PropTypes.node.isRequired,
};

const CompanyOpportunitiesProvider = withUser(withMessage(CompanyOpportunities));

const useCompanyOpportunities = () => useContext(CompanyOpportunitiesContext);

export { CompanyOpportunitiesContext, CompanyOpportunitiesProvider, useCompanyOpportunities };
