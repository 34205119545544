import gql from 'graphql-tag';

export const SKILL_TO_VALIDATE_FRAGMENT = `
  _id
  skillId
  isLiked
  expertise
  origin
  createdAt
  updatedAt
  isImprove
  proofPointId
  userId
  skill {
    _id
    type
    trends {
      _id
      trendIcon
      replaceability
      demand
    }
    industry {
      _id
      name
    }
    synonym(policiesPreset: $policiesPreset) {
      _id
      synonymId
      synonymLang
      synonymString
      synonymLevelDescriptions {
        _id
        level
        description
      }
      companySpecific {
        _id
        isLogoVisible
        definition
      }
    }
  }
`;

export const SKILL_TO_VALIDATE_INPUT_FRAGMENT = gql`
  fragment skillToValidateInputFragment on UserValidateSkill {
    isLiked
    expertise
    isImprove
    proofPointId
  }
`;
