import { useMemo } from 'react';

import useCourseOpportunities from '../../graphql/useCourseOpportunities';

const useStillValidSkills = persistedSelectedSkills => {
  const { loading: isLoading, data: followedOpportunities, error } = useCourseOpportunities();

  const stillValidSkills = useMemo(() => {
    if (error || isLoading || !followedOpportunities || !persistedSelectedSkills) {
      return null;
    }

    return persistedSelectedSkills.filter(({ skillId }) => {
      const allSkillGaps = followedOpportunities.map(({ skillsGap }) => skillsGap).filter(Boolean);
      const skillGapWithTargetSkill = allSkillGaps.find(gap =>
        gap.find(gapSkill => gapSkill._id === skillId)
      );
      const isUpToDate = skillGapWithTargetSkill !== undefined;
      return isUpToDate;
    });
  }, [persistedSelectedSkills, followedOpportunities, error, isLoading]);

  return {
    isLoading,
    stillValidSkills,
  };
};

export default useStillValidSkills;
