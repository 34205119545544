import ReactGA from 'react-ga';

let initialized = false;

export const init = trackingId => {
  ReactGA.initialize(trackingId, {
    // debug: true,
  });
  initialized = true;
};

export const setUserId = userId => {
  ReactGA.set({ userId });
};

export const setClientName = clientName => {
  ReactGA.set({ dimension1: clientName });
};

export const trackPageview = location => {
  if (initialized) {
    ReactGA.pageview(location);
  }
};

export const trackModalview = modalName => {
  if (initialized) {
    ReactGA.modalview(modalName);
  }
};

export const trackEvent = options => {
  if (initialized) {
    ReactGA.event(options);
  }
};
