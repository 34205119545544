import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Skeleton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { Notes } from 'UI/components/Notes';
import { useUserId } from 'UI/contexts/user';
import { useT } from 'UI/lib';
import useMembersSkillProfile from 'UI/pages/restrict/company/members/components/details/components/MemberSkillsProfile/useMemberSkillsProfile';
import useCompanyOpportunitiesDetails from 'UI/pages/restrict/opportunities/CompanyOpportunities/useCompanyOpportunitiesDetails';
import { useOpportunitySkills } from 'UI/pages/restrict/opportunities/components/AccordionOpportunity/useOpportunitySkills';
import { useOpportunitySkillSynonyms } from 'UI/pages/restrict/opportunities/components/AccordionOpportunity/useOpportunitySkillSynonyms';
import SelectedSkills from 'UI/pages/restrict/personalDevelopment/components/SelectedSkills';
import DateInput from 'UI/pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/components/DateInput';
import SubmitArea from 'UI/pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/components/SubmitArea';
import { useStatuses } from 'UI/pages/restrict/personalDevelopment/RecommendationsLXP/useStatuses';

import { format } from 'date-fns';
import defaults from 'lodash/defaults';

const AddEditOpportunityAsTargetRoleModal = ({
  onFinish,
  onSubmit,
  isEditMode = false,
  isViewOnly = false,
  initialData,
  targetRole,
  opportunity,
}) => {
  const contextUserId = useUserId();
  const userId = targetRole?.userId ?? contextUserId;
  const { skills: userSkills } = useMembersSkillProfile({ personId: userId });

  const { details, loading: detailsLoading } = useCompanyOpportunitiesDetails({
    id: opportunity._id,
    type: 'CompanyOpportunity',
    personId: userId,
  });
  const loadElements = skills => {
    return skills
      .filter(Boolean)
      .flat()
      .filter(item => item?.skillId)
      .map(({ skillId }) => skillId);
  };

  const skillIdsToLoadSynonyms = useMemo(() => {
    const skills = [details?.missing?.mostRelevant, details?.missing?.relevant];
    return loadElements(skills);
  }, [details?.missing]);

  const { skills: skillsWithSynonyms, isLoading: skillsLoading } = useOpportunitySkillSynonyms({
    opportunityId: opportunity._id,
    skillSynonymPoliciesPreset: 'capabilitySkills',
    skillIds: skillIdsToLoadSynonyms,
  });

  const { matchingMostRelevantSkills, matchingRelevantSkills } = useOpportunitySkills({
    userSkills,
    matchingSkills: details?.matching || {},
    missingSkills: details?.missing || {},
    skillsWithSynonyms,
  });
  const matchingSkills = [...matchingMostRelevantSkills, ...matchingRelevantSkills].map(
    item => item.skill
  );
  const t = useT();
  const statuses = useStatuses();
  const [form, setForm] = useState(() =>
    defaults(initialData, {
      capabilityId: opportunity?._id,
      title: opportunity?.job?.name ?? '',
      description: '',
      startedAt: format(new Date(), 'yyyy-MM-dd'),
      endedAt: format(new Date(), 'yyyy-MM-dd'),
      contactEmail: opportunity?.contactEmail ?? '',
      notes: '',
      status: 'planned',
      customLink: opportunity?.link ?? '',
      skills: null,
      matchingSkills: null,
    })
  );

  useEffect(() => {
    if (!isEditMode) {
      setForm(prevForm => ({
        ...prevForm,
        description: details?.description?.replace(/<[^>]+>/g, ''),
        contactEmail: details?.contactEmail,
      }));
    }
  }, [details?.contactEmail, details?.description, isEditMode]);

  const handleSubmit = useCallback(() => {
    const newData = {
      capabilityId: form.capabilityId,
      endedAt: form.endedAt,
      notes: form.notes,
      status: form.status,
    };
    if (isEditMode) {
      onSubmit(newData, targetRole.id);
    } else {
      onSubmit(newData);
    }
    onFinish();
  }, [
    form.capabilityId,
    form.endedAt,
    form.notes,
    form.status,
    isEditMode,
    onFinish,
    onSubmit,
    targetRole?.id,
  ]);

  const setFormOnChange = useCallback((field, value) => setForm({ ...form, [field]: value }), [
    form,
  ]);

  const handleChange = useCallback((field, value) => setFormOnChange(field, value), [
    setFormOnChange,
  ]);

  const isSubmitButtonEnabled = form.endedAt;
  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="title"
              label={t(
                'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_title'
              )}
              type="text"
              required
              value={form.title}
              error={!Boolean(form.title)}
              onChange={e => handleChange('title', e.target.value)}
              disabled
              data-test="target-role-title"
              multiline
              maxRows="2"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <DateInput
              id="endedAt"
              label={t('restrict:personal_development.target_roles.end_date')}
              value={form.endedAt}
              error={!Boolean(form.endedAt)}
              required
              onDateChange={(id, value) => handleChange('endedAt', value)}
              disabled={isViewOnly}
              data-test="target-role-ended-date"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl data-test="status-select-form">
              <InputLabel variant="standard">{t('restrict:pd.status_field')}</InputLabel>
              <Select
                variant="standard"
                id="status"
                onChange={e => handleChange('status', e.target.value)}
                value={form.status}
                data-test="target-role-status"
                disabled={isViewOnly}
              >
                {statuses.map(status => (
                  <MenuItem key={status.value} value={status.value}>
                    {t(status.translationKey)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} data-test="skills-part">
            {(skillsLoading || detailsLoading) && <Skeleton height={60} />}
            {skillsWithSynonyms && skillsWithSynonyms.length > 0 && (
              <>
                <Typography variant="body1">
                  {t(
                    'restrict:personal_development.recommendations_add_knowledge_exch_to_plan_skills'
                  )}
                </Typography>
                <SelectedSkills
                  skills={skillsWithSynonyms}
                  isEditMode={false}
                  withInteractiveSkills={false}
                />
              </>
            )}
          </Grid>
          <Grid item xs={12} data-test="matching-skills-part">
            {detailsLoading && <Skeleton height={60} />}
            {matchingSkills && matchingSkills.length > 0 && (
              <>
                <Typography>{t('restrict:opportunities.top_matching_skills')}</Typography>
                <SelectedSkills skills={matchingSkills} withInteractiveSkills={false} />
              </>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="contactEmail"
              label={t('restrict:company_view.capability_contact_email')}
              value={form.contactEmail}
              data-test="target-role-contact-email"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="opportunityUrl"
              label={t('restrict:personal_development.target_roles.add_dialog_link')}
              type="url"
              value={form.customLink}
              data-test="target-role-url"
              multiline
              maxRows="2"
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              id="description"
              label={t(
                'restrict:personal_development.recommendations_add_course_to_plan_description'
              )}
              multiline
              maxRows={3}
              value={form.description}
              onChange={e => handleChange('description', e.target.value)}
              disabled
              data-test="target-role-description-url"
            />
          </Grid>
          <Grid item xs={12}>
            <Notes
              label={t('common:notes')}
              value={form.notes}
              onValueChange={value => handleChange('notes', value)}
              disabled={isViewOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={20}>
        <SubmitArea
          onFinish={onFinish}
          isSubmitButtonEnabled={isSubmitButtonEnabled}
          buttonLabel={isEditMode ? t('restrict:pd.update_goals.save_button') : t('common:add')}
          onSubmit={handleSubmit}
          isDisabled={isViewOnly}
        />
      </Grid>
    </Grid>
  );
};

export default AddEditOpportunityAsTargetRoleModal;
