import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { GET_COMPANY_OPPORTUNITIES_DETAILS } from 'UI/pages/restrict/opportunities/CompanyOpportunities/companyOpportunityDetails';

const useCompanyOpportunitiesDetails = ({ id, type, personId }) => {
  const { data, error, loading } = useQuery(GET_COMPANY_OPPORTUNITIES_DETAILS, {
    variables: { _id: id, personId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip: !id || type !== 'CompanyOpportunity',
  });

  const details = useMemo(() => {
    if (error || !data?.companyOpportunityDetails) {
      return null;
    }
    return data.companyOpportunityDetails;
  }, [data, error]);

  return {
    loading,
    details,
    error,
  };
};

export default useCompanyOpportunitiesDetails;
