import StatusIcons from 'UI/constants/StatusIcons';

const isValidUrl = string => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

const parseJsonSafe = (json, fallbackValue) => {
  try {
    return JSON.parse(json);
  } catch (error) {
    return fallbackValue;
  }
};

const findDomElementAncestor = (element, predicate) => {
  if (predicate(element.parentElement)) {
    return element.parentElement;
  }

  if (element === document.body) {
    return null;
  }

  return findDomElementAncestor(element.parentElement, predicate);
};

// This helper already exists in different form as a hook in
// ui/customHooks/useQuery.js. Since hooks cannot be used in React classes
// I created the following
const getUrlQueryParam = parameter => {
  const search = window.location.search;
  return new URLSearchParams(search).get(parameter);
};

const showStatusIcon = (value, primaryColor) => {
  const statuses = StatusIcons(primaryColor);
  if (value) {
    return statuses[value];
  }
  return statuses.pending;
};

const getRandomInt = (min, max) => {
  return min + Math.round(Math.random() * (max - min));
};

const getIsNumber = value => {
  return typeof value === 'number';
};

export {
  findDomElementAncestor,
  getIsNumber,
  getRandomInt,
  getUrlQueryParam,
  isValidUrl,
  parseJsonSafe,
  showStatusIcon,
};
