import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import usePersistedFiltersLxp from '../pages/restrict/learn-lxp/Course/graphql/usePersistedFiltersLxp';
import usePersistSelectedFiltersLxp from '../pages/restrict/learn-lxp/Course/graphql/usePersistSelectedFiltersLxp';
import useSelectedSkills from '../pages/restrict/learn-lxp/Course/SkillsFilterPanel/hooks/useSelectedSkills';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

const LearningLxpFiltersContext = React.createContext();
const useLearningLXPFilters = () => useContext(LearningLxpFiltersContext);

const LearningLxpFilters = ({ children }) => {
  const {
    persistedSelectedSkills,
    isPromoted: promoted,
    learningType,
    isLoading,
  } = usePersistedFiltersLxp();
  const savedFilters = useMemo(
    () =>
      omitBy(
        {
          persistedSelectedSkills,
          promoted,
          learningType,
        },
        isUndefined
      ),
    [persistedSelectedSkills, promoted, learningType]
  );
  const [filters, setFilters] = useState(savedFilters);
  const {
    persistSelectedFilterBar,
    loading: isLoadingPersistedFilters,
  } = usePersistSelectedFiltersLxp();
  const { selectedSkills, isLoading: isLoadingSkills } = useSelectedSkills();

  //TODO: Rethink the logic if needed
  useEffect(() => {
    setFilters(state => ({ ...state, promoted, learningType, isPromoted: promoted }));
  }, [promoted, learningType]);

  const handlePromotedChange = useCallback(
    promoted => {
      setFilters(state => ({ ...state, isPromoted: promoted }));
      persistSelectedFilterBar({
        ...savedFilters,
        promoted,
        skills: selectedSkills,
      });
    },
    [persistSelectedFilterBar, savedFilters, selectedSkills]
  );

  const handleLearningTypes = useCallback(
    learningType => {
      setFilters(state => ({ ...state, learningType }));
      persistSelectedFilterBar({
        ...savedFilters,
        learningType: learningType,
        skills: selectedSkills,
      });
    },
    [persistSelectedFilterBar, savedFilters, selectedSkills]
  );

  const handleSkills = useCallback(
    skills => {
      setFilters(state => ({ ...state, skills }));
      persistSelectedFilterBar({
        ...savedFilters,
        skills: skills,
      });
    },
    [persistSelectedFilterBar, savedFilters]
  );

  const resetAllLearnLXPFilters = useCallback(() => {
    setFilters({
      promoted: false,
      learningType: [],
      skills: [],
    });
    persistSelectedFilterBar({
      promoted: false,
      learningType: [],
      skills: [],
    });
  }, [persistSelectedFilterBar]);

  return (
    <LearningLxpFiltersContext.Provider
      value={{
        promoted: filters?.isPromoted ?? false,
        learningType: filters?.learningType,
        resetAllLearnLXPFilters,
        handlePromotedChange,
        handleLearningTypes,
        handleSkills,
        isLoading: isLoading || isLoadingPersistedFilters || isLoadingSkills,
      }}
    >
      {children}
    </LearningLxpFiltersContext.Provider>
  );
};

export {
  LearningLxpFiltersContext,
  LearningLxpFilters as LearningLxpFiltersProvider,
  useLearningLXPFilters,
};
