import gql from 'graphql-tag';

const GET_COURSE_OPPORTUNITIES_LXP = gql`
  query getCourseOpportunitiesLXP {
    coursesOpportunities {
      id
      opportunityId
      opportunityName
      type
      skillsGap {
        _id
      }
    }
  }
`;

const GET_LEARNING_TYPES_LXP = gql`
  query learningTypesFiltersLxp {
    learningTypesFiltersLxp {
      id
      name
    }
  }
`;

const GET_COURSES_USER_PERSISTED_FILTERS_LXP = gql`
  query GET_COURSES_USER_PERSISTED_FILTERS_LXP {
    coursesPersistedFiltersLxp {
      promoted
      learningType
      skills {
        skillId
        synonymId
      }
    }
  }
`;

export {
  GET_COURSE_OPPORTUNITIES_LXP,
  GET_COURSES_USER_PERSISTED_FILTERS_LXP,
  GET_LEARNING_TYPES_LXP,
};
