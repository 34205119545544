import React, { useCallback } from 'react';

import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import BasicTooltip from 'UI/components/BasicTooltip/BasicTooltip';
import { useT } from 'UI/lib';

import styles from './styles';

const useStyles = makeStyles(styles);

const SubmitArea = ({
  onFinish,
  onSubmit,
  buttonLabel,
  tooltipLabel,
  isSubmitButtonEnabled,
  isDisabled,
}) => {
  const t = useT();
  const classes = useStyles();

  const onModalCloseCallback = useCallback(() => {
    onFinish();
  }, [onFinish]);

  return (
    <div className={classes.buttons}>
      <Button variant="outlined" onClick={onModalCloseCallback} data-test="close-button">
        {t(isDisabled ? 'common:close' : 'common:cancel')}
      </Button>
      {!isDisabled && (
        <div className={classes.submitButton}>
          {Boolean(!isSubmitButtonEnabled) && (
            <BasicTooltip title={tooltipLabel} arrow placement="bottom" sxTooltip={{ padding: 10 }}>
              <div>
                <Button
                  variant="outlined"
                  onClick={null}
                  disabled={!isSubmitButtonEnabled}
                  data-test={buttonLabel}
                >
                  {buttonLabel}
                </Button>
              </div>
            </BasicTooltip>
          )}
          {Boolean(isSubmitButtonEnabled) && (
            <Button
              variant="outlined"
              onClick={onSubmit}
              disabled={!isSubmitButtonEnabled}
              data-test={buttonLabel}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SubmitArea;
