import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  Container: {
    padding: theme.spacing(5) * 2,
    justifyContent: 'center',
  },
}));

const Spinner = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.Container}>
      <Grid item>
        <CircularProgress aria-label="circular progress icon" />
      </Grid>
    </Grid>
  );
};

export default Spinner;
