import React, { Component } from 'react';

import { clientLogger } from 'UI/lib';

import getApolloClient from '../../getApolloClient';
import { getGqlQuerySessionVariables } from '../apolloStorage';
import {
  marketOpportunityRemove,
  marketOpportunityRestore,
  marketOpportunityUpdate,
} from '../apolloStorage/marketOpportunities';
import {
  MARKET_OPPORTUNITY_FOLLOW,
  MARKET_OPPORTUNITY_REMOVE,
  MARKET_OPPORTUNITY_RESTORE,
  MARKET_OPPORTUNITY_UNFOLLOW,
} from '../mutations/marketOpportunities';
import { GET_MARKET_OPPORTUNITIES } from '../pages/restrict/opportunities/MarketOpportunities/marketOpportunities.query';
import { GET_VIEWER_DELETED_OPPORTUNITIES } from '../queries/userOpportunities';
import { withMessage } from '.';
import { withUser } from './user';
import PropTypes from 'prop-types';

const MarketOpportunitiesContext = React.createContext();

const generateDefaultOptimisticResponseObject = ({ method, input, _id }) => ({
  __typename: 'Mutation',
  [method]: {
    __typename: 'MarketOpportunity',
    _id,
    ...input,
  },
});

const mutationReducer = ({ action, payload = {}, details = {} }) => {
  const { _id, input } = payload;
  const { optimisticItem = {}, opportunity = {} } = details;

  const refetchQueries = [];

  let mutation;
  let method;
  let optmisticMethod;
  let optimisticResponse;

  const opportunitiesPageQuery = getGqlQuerySessionVariables(GET_MARKET_OPPORTUNITIES);
  const deletedOpportunitiesPageQuery = getGqlQuerySessionVariables(
    GET_VIEWER_DELETED_OPPORTUNITIES
  );

  const isOpportunityPage = window.location.pathname.indexOf('/opportunities/market') > -1;
  const isDeletedOpportunityPage = window.location.pathname.indexOf('/opportunities/deleted') > -1;

  const isToUpdateOpportunities = opportunitiesPageQuery && isOpportunityPage;
  const isToUpdateDeletedOpportunities = deletedOpportunitiesPageQuery && isDeletedOpportunityPage;

  switch (action) {
    case 'follow':
      mutation = MARKET_OPPORTUNITY_FOLLOW;
      method = 'marketOpportunityFollow';
      optmisticMethod = marketOpportunityUpdate;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
        input,
      });
      break;
    case 'unfollow':
      mutation = MARKET_OPPORTUNITY_UNFOLLOW;
      method = 'marketOpportunityUnfollow';
      optmisticMethod = marketOpportunityUpdate;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
        input,
      });
      break;
    case 'remove':
      mutation = MARKET_OPPORTUNITY_REMOVE;
      method = 'marketOpportunityRemove';
      optmisticMethod = marketOpportunityRemove;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
      });
      // remove mutation just returns a boolean
      optimisticResponse[method] = true;
      if (isToUpdateDeletedOpportunities) {
        refetchQueries.push(deletedOpportunitiesPageQuery);
      }
      break;
    case 'restore':
      mutation = MARKET_OPPORTUNITY_RESTORE;
      method = 'marketOpportunityRestore';
      optmisticMethod = marketOpportunityRestore;
      optimisticResponse = generateDefaultOptimisticResponseObject({
        method,
        _id,
      });
      optimisticResponse[method] = {
        ...optimisticResponse[method],
        _id,
        covered: 0,
        follow: false,
        job: {
          __typename: 'Job',
          _id,
          trendIcon: 'stable',
          name: null,
        },
        ...optimisticItem,
      };
      if (isToUpdateOpportunities) {
        refetchQueries.push(opportunitiesPageQuery);
      }
      if (isToUpdateDeletedOpportunities) {
        refetchQueries.push(deletedOpportunitiesPageQuery);
      }
      break;

    default:
      break;
  }

  const variables = {
    _id,
  };

  if (action === 'follow') {
    variables.policiesPreset =
      opportunity.__typename === 'MarketOpportunity' ? 'default' : 'capabilitySkills';
  }

  return getApolloClient().mutate({
    mutation,
    variables,
    optimisticResponse,
    refetchQueries,
    update: (store, { data }) => {
      const mutationData = data[method];

      try {
        optmisticMethod({
          store,
          _id,
          input,
          mutationData,
          opportunitiesPageQuery,
          deletedOpportunitiesPageQuery,
          isToUpdateOpportunities,
          isToUpdateDeletedOpportunities,
        });
      } catch (error) {
        clientLogger.error(error);
      }
    },
  });
};

/* eslint-disable react/no-unused-state */

class MarketOpportunities extends Component {
  state = {};

  follow = (_id, details) =>
    mutationReducer({
      action: 'follow',
      payload: {
        _id,
        input: {
          follow: true,
        },
      },
      details,
    });

  unfollow = _id =>
    mutationReducer({
      action: 'unfollow',
      payload: {
        _id,
        input: {
          follow: false,
        },
      },
    });

  remove = _id =>
    mutationReducer({
      action: 'remove',
      payload: {
        _id,
      },
    });

  restore = (_id, details) =>
    mutationReducer({
      action: 'restore',
      payload: {
        _id,
      },
      details,
    });

  render() {
    const { state } = this;
    const { children } = this.props;

    return (
      <MarketOpportunitiesContext.Provider
        value={{
          state,
          follow: this.follow,
          unfollow: this.unfollow,
          remove: this.remove,
          restore: this.restore,
        }}
      >
        {children}
      </MarketOpportunitiesContext.Provider>
    );
  }
}

MarketOpportunities.propTypes = {
  children: PropTypes.node.isRequired,
};

const MarketOpportunitiesProvider = withUser(withMessage(MarketOpportunities));

function withMarketOpportunities(Children) {
  return function WrappedComponent(props) {
    return (
      <MarketOpportunitiesContext.Consumer>
        {data => <Children {...props} marketOpportunities={data} />}
      </MarketOpportunitiesContext.Consumer>
    );
  };
}

export { MarketOpportunitiesContext, MarketOpportunitiesProvider, withMarketOpportunities };
