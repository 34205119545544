import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useExplainVideoURL = videoType => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language.trim();
  const { explainVideos } = PALYX_WHITE_LABEL;
  return useMemo(() => {
    if (!explainVideos) {
      return null;
    }
    const src = explainVideos[videoType];
    if (src) {
      return src[currentLanguage] || src['en'] || null;
    }
    return null;
  }, [currentLanguage, videoType, explainVideos]);
};
export default useExplainVideoURL;
