import React, { useCallback } from 'react';

import TextField from '@mui/material/TextField';
import { DatePicker, PickersDay } from '@mui/x-date-pickers';

const renderDay = (day, selectedDate, props) => {
  return (
    <PickersDay
      variant="contained"
      sx={theme => ({
        color: theme.palette.getContrastText(theme.palette.custom.primary),
        backgroundColor: theme.palette.custom.primary,
        '&:hover': {
          color: theme.palette.getContrastText(
            theme.palette?.componentColors?.mainButtons?.buttonsHoverBackgroundColor ||
              theme.palette.custom.secondary
          ),
          backgroundColor:
            theme.palette?.componentColors?.mainButtons?.buttonsHoverBackgroundColor ||
            theme.palette.custom.secondary,
        },
      })}
      {...props}
    >
      {day.getDate()}
    </PickersDay>
  );
};
const DateInput = ({
  className,
  id,
  label,
  value,
  onDateChange,
  helperText,
  disabled,
  error,
  dataTest = 'start-date',
}) => {
  const onDateChangeCallback = useCallback(value => onDateChange(id, value), [id, onDateChange]);

  const onKeyDown = e => {
    e.preventDefault();
  };

  return (
    <div className={className}>
      <DatePicker
        id={id}
        value={value}
        label={label}
        onChange={onDateChangeCallback}
        disabled={disabled}
        data-test={dataTest}
        renderDay={(day, selectedDate, isInCurrentMonth) =>
          renderDay(day, selectedDate, isInCurrentMonth)
        }
        renderInput={params => (
          <TextField
            {...params}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            error={error}
            helperText={helperText}
            onKeyDown={onKeyDown}
          />
        )}
      />
    </div>
  );
};

export default DateInput;
