import { clientLogger } from 'UI/lib';

import gql from 'graphql-tag';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';

export const companyOpportunityUpdate = ({ store, input, mutationData = {} }) => {
  if (!mutationData || !mutationData._id) {
    return null;
  }

  const fragment = gql`
    fragment CompanyOpportunityFields on CompanyOpportunity {
      __typename
      ${Object.keys(input).join(' ')}
    }
  `;

  const dataToWrite = pick(mutationData, Object.keys(input));

  store.writeFragment({
    id: `CompanyOpportunity:${mutationData._id}`,
    fragment,
    data: {
      ...dataToWrite,
      __typename: 'CompanyOpportunity',
    },
  });
};

export const companyOpportunityRemove = ({
  store,
  _id,
  opportunitiesPageQuery,
  isToUpdateOpportunities,
}) => {
  try {
    if (isToUpdateOpportunities) {
      const data = store.readQuery(opportunitiesPageQuery);
      const { items, count, pageInfo } = data.companyOpportunities;

      store.writeQuery({
        ...opportunitiesPageQuery,
        data: {
          ...data,
          companyOpportunities: {
            __typename: 'CompanyOpportunityPagination',
            count: count - 1,
            pageInfo: {
              ...pageInfo,
              itemCount: count - 1,
            },
            items: items.filter(i => i._id !== _id),
          },
        },
      });
    }
  } catch (error) {
    clientLogger.error(error);
  }
};

export const companyOpportunityRestore = ({
  store,
  mutationData = {},
  opportunitiesPageQuery,
  deletedOpportunitiesPageQuery,
  isToUpdateOpportunities,
  isToUpdateDeletedOpportunities,
}) => {
  try {
    if (isToUpdateOpportunities) {
      const data = store.readQuery(opportunitiesPageQuery);
      const { items, count, pageInfo } = data.companyOpportunities;

      store.writeQuery({
        ...opportunitiesPageQuery,
        data: {
          ...data,
          companyOpportunities: {
            __typename: 'CompanyOpportunityPagination',
            count,
            pageInfo,
            items: uniqBy([mutationData, ...items], i => i._id).sort(
              (a, b) => b.covered - a.covered
            ),
          },
        },
      });
    }

    if (isToUpdateDeletedOpportunities) {
      const deletedData = { ...store.readQuery(deletedOpportunitiesPageQuery) };
      const { userOpportunitiesDeleted } = deletedData || {};

      deletedData.userOpportunitiesDeleted = {
        __typename: 'UserOpportunityPagination',
        count: userOpportunitiesDeleted.count - 1,
        pageInfo: {
          ...userOpportunitiesDeleted.pageInfo,
          itemCount: userOpportunitiesDeleted.count - 1,
        },
        items: userOpportunitiesDeleted.items.filter(i => i._id !== mutationData._id),
      };

      store.writeQuery({
        ...deletedOpportunitiesPageQuery,
        data: deletedData,
      });
    }
  } catch (error) {
    clientLogger.error(error);
  }
};
