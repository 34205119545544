import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { gql, useQuery } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import { clientLogger } from 'UI/lib';

import { getBrowserUserCredentials } from '../../../../../../auth/userCredentials';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

const allowedLocations = [
  '/app/personal-development/recommendations',
  '/app/learn-v2/course',
  '/app/my-profile/skills',
];
const usePersistedFilters2 = () => {
  const PegasusConfig = useConfig();
  const { palyxToken } = getBrowserUserCredentials();
  const isCourseAttendedFeatureEnabled = get(
    PegasusConfig,
    'cms.features.learningPageV2.isCourseAttendedFeatureEnabled',
    false
  );

  const hasLXPLearnFeature =
    PegasusConfig.cms?.pages?.personalDevelopment?.recommendations?.courses?.source;
  const location = useLocation();
  const isInAllowedLocation = useMemo(() => {
    return allowedLocations.includes(location.pathname);
  }, [location.pathname]);
  const { loading, data, error } = useQuery(
    gql`
      query GET_COURSES_USER_PERSISTED_FILTERS_V2($isCourseAttendedFeatureEnabled: Boolean!) {
        coursesPersistedFiltersV2 {
          keywords
          languages
          hasAttendedOnly @include(if: $isCourseAttendedFeatureEnabled)
          sourceIds
          skills {
            skillId
            synonymId
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-first',
      nextFetchPolicy: 'CacheAndNetwork',
      variables: { isCourseAttendedFeatureEnabled },
      skip: !palyxToken || hasLXPLearnFeature || !isInAllowedLocation,
    }
  );

  const persistedSelectedLanguages = get(data, 'coursesPersistedFiltersV2.languages');
  const persistedSelectedKeywords = get(data, 'coursesPersistedFiltersV2.keywords');
  const persistedSelectedHasAttended = get(data, 'coursesPersistedFiltersV2.hasAttendedOnly');
  const persistedSelectedSourceIds = get(data, 'coursesPersistedFiltersV2.sourceIds');

  useEffect(() => {
    if (error) {
      clientLogger.error(`usePersistedSelectedSkills - ${error.message}`, error);
    }
  }, [error]);

  const persistedSelectedSkills = useMemo(() => {
    const skills = get(data, 'coursesPersistedFiltersV2.skills');
    return skills && skills.map(skill => pick(skill, ['skillId', 'synonymId']));
  }, [data]);

  return omitBy(
    {
      isLoading: loading,
      error,
      persistedSelectedLanguages,
      persistedSelectedKeywords,
      persistedSelectedHasAttended,
      persistedSelectedSourceIds,
      persistedSelectedSkills,
      countSkills: data?.coursesPersistedFiltersV2?.skills.length,
    },
    isUndefined
  );
};

export default usePersistedFilters2;
