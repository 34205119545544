import { lazy, useCallback } from 'react';

import { Box } from '@mui/material';

import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import { useMessageContext } from 'UI/contexts';
import { clientLogger, useT } from 'UI/lib';

import useImportCustomerSynonyms from '../hooks/useImportCustomerSynonyms';

const DropZone = lazy(() => import('UI/containers/dropZone/dropZone'));

const ImportCompanySynonymsModal = ({ onFinish }) => {
  const message = useMessageContext();
  const t = useT();
  const { importCompanySynonyms, isLoading } = useImportCustomerSynonyms();

  const importFile = useCallback(
    async file => {
      await importCompanySynonyms(file)
        .then(() => {
          message.setMessage({
            text: t('restrict:company_view.customer_synonyms.default.dialog.import_csv_success'),
            type: 'success',
          });
          onFinish();
        })
        .catch(e => {
          clientLogger.error(e);
          message.setMessage({
            text: t('restrict:company_view.customer_synonyms.default.dialog.import_csv_failure'),
            type: 'error',
          });
        });
    },
    [importCompanySynonyms]
  );

  return (
    <Box>
      <LazyComponent>
        <DropZone
          onFile={file => importFile(file)}
          loading={isLoading}
          defaultType={false}
          supportedExtText="csv"
          dropFileHelper="restrict:company_view.customer_synonyms.default.dialog.drop_file_helper"
          defaultErrorInfo="restrict:company_view.customer_synonyms.default.dialog.csv_default_error"
          dataTest="csv_drop_zone"
        />
      </LazyComponent>
    </Box>
  );
};

export default ImportCompanySynonymsModal;
