import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import {
  GET_ALL_ASSIGNED_SKILL_VALIDATIONS,
  QUERY_ASSIGNED_SKILL_VALIDATIONS,
} from 'UI/containers/SkillValidation/AllAssignedSkills/getAllAssignedSkillValidations.query';
import { useMessageContext } from 'UI/contexts';
import useConfig from 'UI/customHooks/useConfig';
import updateQueryCache from 'UI/customHooks/useUpdateQueryCache';
import { clientLogger } from 'UI/lib';
import { getFieldsFromGqlQuery } from 'UI/lib/getFieldsFromGqlQuery';

import { SKILL_TO_VALIDATE_FRAGMENT } from '../skillsToValidate.fragment';
import gql from 'graphql-tag';
import merge from 'lodash/merge';
import { v4 as getUuid } from 'uuid';

export const useAddSkillsToValidate = () => {
  const config = useConfig();
  const message = useMessageContext();
  const skillToValidateSynonymPoliciesPreset =
    config.cms?.components?.SelfAssignedSkills?.skillSynonymPoliciesPreset;

  const [addSkillsToValidateMutation] = useMutation(
    gql`
    mutation ADD_SKILLS_TO_VALIDATE(
      $record: [CreateOneUserValidateSkillInput]
      $policiesPreset: PoliciesPresetInput
    ) {
      userValidateSkillCreateMany(record: $record) {
        __typename
        recordId
        record {
          ${SKILL_TO_VALIDATE_FRAGMENT}
        }
      }
    }
  `,
    {
      onError: error => {
        clientLogger.error(`useAddSkillsToValidate - ${error.message}`, error);
        message.setMessage({
          text: t => t('restrict:default_error_message'),
        });
      },
    }
  );

  const addSkillsToValidate = useCallback(
    skillsToValidate => {
      // Needs to use the different query to not screw up the optimistic response
      const queryObjectEmptyStructure = getFieldsFromGqlQuery(QUERY_ASSIGNED_SKILL_VALIDATIONS);

      const { mutationInput, optimisticResponse } = skillsToValidate.reduce(
        (results, skillToValidate) => {
          const { skill, origin = 'manual' } = skillToValidate;
          const temporaryId = `temp-id-${getUuid()}`;
          results.optimisticResponse.push({
            __typename: 'UserValidateSkillPayload',
            recordId: temporaryId,
            record: merge({}, queryObjectEmptyStructure, {
              _id: temporaryId,
              createdAt: new Date(),
              updatedAt: new Date(),
              origin,
              skill,
            }),
          });

          results.mutationInput.push({
            synonymId: skill.synonym.synonymId,
            origin,
          });

          return results;
        },
        {
          mutationInput: [],
          optimisticResponse: [],
        }
      );

      addSkillsToValidateMutation({
        variables: {
          record: mutationInput,
          policiesPreset: { preset: skillToValidateSynonymPoliciesPreset },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          userValidateSkillCreateMany: optimisticResponse,
        },
        update: (cache, { data: { userValidateSkillCreateMany } }) => {
          updateQueryCache({
            cache,
            query: GET_ALL_ASSIGNED_SKILL_VALIDATIONS,
            variables: {
              suggestedAction: undefined,
              policiesPreset: { preset: skillToValidateSynonymPoliciesPreset },
            },
            update: oldSkillsToValidate => {
              const newSkillsToValidateIds = userValidateSkillCreateMany.map(
                ({ record }) => record.skillId || record?.skill?._id
              );
              const oldSkillsSelfAdded = oldSkillsToValidate.selfAdded?.filter(
                ({ skill }) => !newSkillsToValidateIds.includes(skill._id)
              );
              const newData = userValidateSkillCreateMany
                .map(({ record }) => ({
                  ...record,
                  suggestedBy: null,
                  suggestedAction: null,
                }))
                .concat(oldSkillsSelfAdded);
              return { ...oldSkillsToValidate, selfAdded: newData };
            },
          });
        },
      });
    },
    [addSkillsToValidateMutation, skillToValidateSynonymPoliciesPreset]
  );

  return {
    addSkillsToValidate,
  };
};
