import { Component, lazy, Suspense } from 'react';

const LIBRARY_NAME = 'PureLoadable';

const getWrapperDisplayName = targetDisplayName =>
  targetDisplayName ? `${LIBRARY_NAME}(${targetDisplayName})` : LIBRARY_NAME;

const DefaultLoading = ({ error }) => (error ? <span>{LIBRARY_NAME} loading error</span> : null);

export default ({ loader, loading = DefaultLoading, displayName }) => {
  const LoadingComponent = loading;
  const TargetComponent = lazy(() =>
    loader().catch(e => {
      console.error(e);
      return { default: () => <LoadingComponent error={e} /> };
    })
  );

  class LoadableComponent extends Component {
    state = { loadingError: null };

    static getDerivedStateFromError(boundaryError) {
      return { boundaryError };
    }

    render() {
      const { loadingError, boundaryError } = this.state;
      const error = loadingError || boundaryError;
      if (error) {
        return <LoadingComponent error={error} />;
      }

      return (
        <Suspense fallback={<LoadingComponent error={null} />}>
          <TargetComponent {...this.props} />
        </Suspense>
      );
    }
  }

  LoadableComponent.displayName = getWrapperDisplayName(displayName);
  return LoadableComponent;
};
