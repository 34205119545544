export const VALIDATION_TYPES = {
  B2B: 'b2b',
  MANAGER_ASSESSMENT: 'managerAssessment',
  PD_MEASURE: 'personalDevelopmentMeasure',
  SELF_ADDED: 'selfAdded',
};

export const VALIDATION_TYPES_ARRAY = [
  VALIDATION_TYPES.B2B,
  VALIDATION_TYPES.MANAGER_ASSESSMENT,
  VALIDATION_TYPES.PD_MEASURE,
  VALIDATION_TYPES.SELF_ADDED,
];

export const VALIDATION_TYPES_LOCALIZATION = {
  [VALIDATION_TYPES.B2B]: 'restrict:b2b_assigned_skills',
  [VALIDATION_TYPES.MANAGER_ASSESSMENT]: 'restrict:manager_suggested_skills',
  [VALIDATION_TYPES.SELF_ADDED]: 'restrict:self_assigned_skills',
  [VALIDATION_TYPES.PD_MEASURE]: 'restrict:completed_development_measure_skills',
};
