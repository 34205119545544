import React, { Component, lazy, useContext } from 'react';
import { withTranslation } from 'react-i18next';

import ButtonCustom from 'UI/components/buttonCustom/buttonCustom';
import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import { ERROR_TYPES } from 'UI/constants/errorTypes';
import useConfig from 'UI/customHooks/useConfig';
import SynonymInfo from 'UI/pages/restrict/customerSpecificSkills/CustomizeSynonyms/SynonymsTable/SynonymInfo';
import AddEditOpportunityAsTargetRoleModal from 'UI/pages/restrict/opportunities/components/AccordionOpportunity/components/AddEditOpportunityAsTargetRoleModal';
import UpdateDevelopmentNotesModal from 'UI/pages/restrict/personalDevelopment/MyProfile/components/NotesArea/UpdateDevelopmentNotesModal';
import { AddCourseToPersonalPlanDialog } from 'UI/pages/restrict/personalDevelopment/RecommendationsLXP/components/Learning/AddCourseToPersonalPlanDialog';

import ReportsDownloadTitle from '../containers/ReportDownload/ReportsDownloadTitle';
import CreateCompanySynonymModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/CreateCompanySynonymModal/CreateCompanySynonymModal';
import ImportCompanySynonymsModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/ImportCompanySynonymsModal/ImportCompanySynonymsModal';
import UpdateCompanySynonymModal from '../pages/restrict/customerSpecificSkills/CustomizeSynonyms/UpdateCompanySynonymModal/UpdateCompanySynonymModal';
import { AddProjectToPersonalPlanDialog } from '../pages/restrict/personalDevelopment/Recommendations/components/Projects/AddProjectToPersonalPlanDialog';
import ReplaceSkillWithSynonymModal from '../pages/restrict/skills/SkillsTab/ReplaceSkillWithSynonymModal';
import i18next from 'i18next';
import PropTypes from 'prop-types';

const ManualCreation = lazy(() =>
  import(
    'UI/pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/ManualCreation/ManualCreation'
  )
);
const CheckIn = lazy(() =>
  import('UI/pages/restrict/personalDevelopment/DevelopmentPlan/AddMoreGoalsModals/CheckIn/CheckIn')
);
const AddSkillsManually = lazy(() => import('UI/containers/AddSkillsManually/AddSkillsManually'));
const JobAds = lazy(() => import('UI/containers/jobAds/jobAds'));
const JobSkillsSuggestions = lazy(() =>
  import('UI/containers/jobSkillsSuggestions/jobSkillsSuggestions')
);
const NewsDetails = lazy(() => import('UI/containers/newsDetails/newsDetails'));
const PrivacyPolicy = lazy(() => import('UI/containers/privacyPolicy/privacyPolicy'));
const RefineSkills = lazy(() => import('UI/containers/RefineSkills/RefineSkills'));
const RefineSkillsByJob = lazy(() => import('UI/containers/refineSkillsByJob/refineSkillsByJob'));
const ScanDocument = lazy(() => import('UI/containers/ScanDocument/ScanDocument'));
const TermsAndConditions = lazy(() =>
  import('UI/containers/termsAndConditions/termsAndConditions')
);
const ExplainVideo = lazy(() => import('../containers/explainVideo/explainVideo'));
const ExplainFeatureVideo = lazy(() =>
  import('UI/containers/ExplainFeatureVideo/ExplainFeatureVideo')
);
const ManuallyAddedSkillsValidation = lazy(() =>
  import('UI/containers/ManuallyAddedSkillsValidation/ManuallyAddedSkillsValidation')
);
const AddEditCapabilities = lazy(() =>
  import('UI/containers/AddEditCapabilities/AddEditCapabilities')
);
const CapabilityScanDocument = lazy(() =>
  import('UI/containers/capaScanDocument/CapabilityScanDocument')
);
const CapaSkillsByJob = lazy(() => import('UI/containers/capaSkillsByJob/capaSkillsByJob'));
const MobilityCalculator = lazy(() => import('UI/containers/MobilityCalculator'));
const CapabilitySkillsSuggestions = lazy(() => import('UI/containers/CapabilitySkillsSuggestions'));
const SearchAndAddCompanySpecificSkills = lazy(() =>
  import('UI/containers/SearchAndAddCompanySpecificSkills')
);
const SelectGlobalOrganization = lazy(() =>
  import('UI/containers/SelectOrganization/SelectGlobalOrganization')
);
const AddKnowledgeExchangeToPersonalPlanDialog = lazy(() =>
  import(
    'UI/pages/restrict/personalDevelopment/Recommendations/components/SharedSkills/AddKnowledgeExchangeToPersonalPlanDialog'
  )
);
const ReportDownload = lazy(() => import('UI/containers/ReportDownload/ReportDownload'));
const AddSkillSuggestion = lazy(() =>
  import('UI/components/AddSkillSuggestion/AddSkillSuggestion')
);
const DialogCustom = lazy(() => import('UI/components/dialogCustom/dialogCustom'));

const ModalsContext = React.createContext();

/* eslint-disable react/no-unused-state */

function Modals({ onClose, title }) {
  const config = useConfig();
  const locizeAddToDevelopmentPlan =
    config.cms?.locize?.addToDevelopmentPlan ??
    'restrict:personal_development.recommendations_add_course_to_plan_header';
  const personalDevelopmentTitleStyles = {
    display: 'flex',
    alignItems: 'center',
  };

  if (!i18next.t) {
    return {};
  }
  return {
    ADD_SKILLS_MANUALLY: {
      children: (
        <LazyComponent>
          <AddSkillsManually onFinish={() => onClose('ADD_SKILLS_MANUALLY')} />
        </LazyComponent>
      ),
    },
    JOB_ADS: {
      children: (
        <LazyComponent>
          <JobAds onFinish={() => onClose('JOB_ADS')} />
        </LazyComponent>
      ),
      actions: (
        <ButtonCustom onClick={() => onClose('JOB_ADS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
      maxWidth: false,
    },
    JOB_SKILLS_SUGGESTIONS: {
      children: (
        <LazyComponent>
          <JobSkillsSuggestions onFinish={() => onClose('JOB_SKILLS_SUGGESTIONS')} />
        </LazyComponent>
      ),
    },
    NEWS_DETAILS: {
      children: (
        <LazyComponent>
          <NewsDetails />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('NEWS_DETAILS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    PRIVACY_POLICY: {
      title: title || i18next.t('common:privacy_policy_title_modal'),
      children: (
        <LazyComponent>
          <PrivacyPolicy />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('PRIVACY_POLICY')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    REFINE_SKILLS: {
      title: i18next.t('restrict:refine_skills_title_modal'),
      children: (
        <LazyComponent>
          <RefineSkills />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('REFINE_SKILLS')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    REFINE_SKILLS_BY_JOB: {
      children: (
        <LazyComponent>
          <RefineSkillsByJob onFinish={() => onClose('REFINE_SKILLS_BY_JOB')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    SCAN_DOCUMENT: {
      children: (
        <LazyComponent>
          <ScanDocument onFinish={() => onClose('SCAN_DOCUMENT')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    TERMS_AND_CONDITION: {
      title: title || i18next.t('common:terms_modal_title'),
      children: (
        <LazyComponent>
          <TermsAndConditions />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('TERMS_AND_CONDITION')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    EXPLAIN_VIDEO: {
      children: (
        <LazyComponent>
          <ExplainVideo />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('EXPLAIN_VIDEO')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    EXPLAIN_VIDEO_BUTTON: {
      children: (
        <LazyComponent>
          <ExplainFeatureVideo />
        </LazyComponent>
      ),
      fullScreen: false,
      actions: (
        <ButtonCustom onClick={() => onClose('EXPLAIN_VIDEO_BUTTON')} color="primary">
          {i18next.t('common:close')}
        </ButtonCustom>
      ),
    },
    USER_VALIDATE_SKILLS: {
      title: i18next.t('restrict:validate_skills_modal_title'),
      children: (
        <LazyComponent>
          <ManuallyAddedSkillsValidation onFinish={() => onClose('USER_VALIDATE_SKILLS')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    ADD_EDIT_CAPABILITY: {
      blockBackdropClick: true,
      children: (
        <LazyComponent>
          <AddEditCapabilities onFinish={() => onClose('ADD_EDIT_CAPABILITY')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    CAPA_SCAN_DOCUMENT: {
      children: (
        <LazyComponent>
          <CapabilityScanDocument onFinish={() => onClose('CAPA_SCAN_DOCUMENT')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    CAPA_SKILLS_BY_JOB: {
      children: (
        <LazyComponent>
          <CapaSkillsByJob onFinish={() => onClose('CAPA_SKILLS_BY_JOB')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    MOBILITY_CALCULATOR: {
      children: (
        <LazyComponent>
          <MobilityCalculator onFinish={() => onClose('MOBILITY_CALCULATOR')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    CAPABILITY_SKILLS_SUGGESTION: {
      children: (
        <LazyComponent>
          <CapabilitySkillsSuggestions onFinish={() => onClose('CAPABILITY_SKILLS_SUGGESTION')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    SEARCH_COMPANY_SPECIFIC_SKILLS: {
      children: (
        <LazyComponent>
          <SearchAndAddCompanySpecificSkills
            onFinish={() => onClose('SEARCH_COMPANY_SPECIFIC_SKILLS')}
          />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    SELECT_ORGANIZATION: {
      children: (
        <LazyComponent>
          <SelectGlobalOrganization onFinish={() => onClose('SELECT_ORGANIZATION')} />
        </LazyComponent>
      ),
      fullScreen: false,
    },
    ADD_KNOWLEDGE_EXCHANGE_TO_PERSONAL_PLAN: {
      title: i18next.t(locizeAddToDevelopmentPlan),
      children: (
        <LazyComponent>
          <AddKnowledgeExchangeToPersonalPlanDialog
            onFinish={() => onClose('ADD_KNOWLEDGE_EXCHANGE_TO_PERSONAL_PLAN')}
          />
        </LazyComponent>
      ),
    },
    UPDATE_KNOWLEDGE_EXCHANGE_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_knowledge_exch_in_plan_header'
          )}
        </div>
      ),
      children: (
        <LazyComponent>
          <AddKnowledgeExchangeToPersonalPlanDialog
            onFinish={() => onClose('UPDATE_KNOWLEDGE_EXCHANGE_IN_PERSONAL_PLAN')}
          />
        </LazyComponent>
      ),
    },
    ADD_COURSE_TO_PERSONAL_PLAN: {
      title: i18next.t(locizeAddToDevelopmentPlan),
      children: (
        <LazyComponent>
          <AddCourseToPersonalPlanDialog onFinish={() => onClose('ADD_COURSE_TO_PERSONAL_PLAN')} />
        </LazyComponent>
      ),
    },
    ADD_PROJECT_TO_PERSONAL_PLAN: {
      title: i18next.t(locizeAddToDevelopmentPlan),
      children: (
        <AddProjectToPersonalPlanDialog onFinish={() => onClose('ADD_PROJECT_TO_PERSONAL_PLAN')} />
      ),
    },
    UPDATE_COURSE_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:personal_development.recommendations_update_course_in_plan_header')}
        </div>
      ),
      children: (
        <AddCourseToPersonalPlanDialog onFinish={() => onClose('UPDATE_COURSE_IN_PERSONAL_PLAN')} />
      ),
    },
    UPDATE_PROJECT_IN_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:personal_development.recommendations_update_project_in_plan_header')}
        </div>
      ),
      children: (
        <AddProjectToPersonalPlanDialog
          onFinish={() => onClose('UPDATE_PROJECT_IN_PERSONAL_PLAN')}
        />
      ),
    },
    ADD_CHECKIN_TO_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:pd.add_further_goals.check-in_tab')}
        </div>
      ),
      children: (
        <LazyComponent>
          <CheckIn onFinish={() => onClose('ADD_CHECKIN_TO_PERSONAL_PLAN')} />
        </LazyComponent>
      ),
    },
    ADD_MANUAL_GOAL_TO_PERSONAL_PLAN: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t('restrict:pd.add_further_goals.add_manually_tab')}
        </div>
      ),
      children: (
        <LazyComponent>
          <ManualCreation onFinish={() => onClose('ADD_MANUAL_GOAL_TO_PERSONAL_PLAN')} />
        </LazyComponent>
      ),
    },
    PERSONAL_PLAN_EDIT_CHECK_IN_GOAL: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_check-in_measure_in_plan_header'
          )}
        </div>
      ),
      children: (
        <LazyComponent>
          <CheckIn onFinish={() => onClose('PERSONAL_PLAN_EDIT_CHECK_IN_GOAL')} />
        </LazyComponent>
      ),
    },
    PERSONAL_PLAN_EDIT_MANUALLY_ADDED_GOAL: {
      title: (
        <div style={personalDevelopmentTitleStyles}>
          {i18next.t(
            'restrict:personal_development.recommendations_update_manually_added_measure_in_plan_header'
          )}
        </div>
      ),
      children: (
        <LazyComponent>
          <ManualCreation onFinish={() => onClose('PERSONAL_PLAN_EDIT_MANUALLY_ADDED_GOAL')} />
        </LazyComponent>
      ),
    },
    REPORT_DOWNLOAD: {
      title: <ReportsDownloadTitle title={title} />,
      children: (
        <LazyComponent>
          <ReportDownload />
        </LazyComponent>
      ),
    },
    REPLACE_SKILL_WITH_SYNONYM: {
      children: (
        <ReplaceSkillWithSynonymModal onFinish={() => onClose('REPLACE_SKILL_WITH_SYNONYM')} />
      ),
    },
    CREATE_COMPANY_SYNONYM: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.create_title'),
      children: <CreateCompanySynonymModal onFinish={() => onClose('CREATE_COMPANY_SYNONYM')} />,
    },
    UPDATE_COMPANY_SYNONYM: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.update_title'),
      children: <UpdateCompanySynonymModal onFinish={() => onClose('UPDATE_COMPANY_SYNONYM')} />,
    },
    UPLOAD_COMPANY_SYNONYMS: {
      title: i18next.t('restrict:company_view.customer_synonyms.default.dialog.import_csv_title'),
      children: <ImportCompanySynonymsModal onFinish={() => onClose('UPLOAD_COMPANY_SYNONYMS')} />,
    },
    SUGGEST_SKILL: {
      children: (
        <LazyComponent>
          <AddSkillSuggestion onFinish={() => onClose('SUGGEST_SKILL')} />
        </LazyComponent>
      ),
    },
    DESCRIPTION_COMPANY_SYNONYMS: {
      children: <SynonymInfo />,
    },
    UPDATE_NOTES_DEVELOPMENT: {
      children: (
        <UpdateDevelopmentNotesModal onFinish={() => onClose('UPDATE_NOTES_DEVELOPMENT')} />
      ),
    },
    ADD_EDIT_OPPORTUNITY_AS_TARGET_ROLE: {
      children: (
        <AddEditOpportunityAsTargetRoleModal
          onFinish={() => onClose('ADD_EDIT_OPPORTUNITY_AS_TARGET_ROLE')}
        />
      ),
    },
  };
}

const defaultState = {};

const modals = Modals({});

Object.keys(modals).forEach(key => {
  defaultState[key] = {
    opened: false,
    params: {},
    currentTitle: modals[key].title,
  };
});

class ModalsProviderComponent extends Component {
  state = defaultState;

  open = (modal, params) => {
    this.setState({
      [modal]: Object.assign({}, this.state[modal], {
        opened: true,
        params,
      }),
    });
  };
  close = modal =>
    this.setState({
      [modal]: Object.assign({}, this.state[modal], {
        opened: false,
      }),
    });

  render() {
    const { children } = this.props;

    const ModalComponents = Modals({
      onClose: this.close,
    });

    return (
      <ModalsContext.Provider
        value={{
          state: this.state,
          reset: () => this.setState(defaultState),
          open: this.open,
          close: this.close,
        }}
      >
        {children}
        {Object.keys(ModalComponents).map(modal => {
          const props = ModalComponents[modal];
          const { opened, params = {} } = this.state[modal];
          return (
            <LazyComponent
              dialogHelper={() => this.close(modal)}
              errorDisplayType={ERROR_TYPES.DIALOG}
              key={modal.toString()}
            >
              <DialogCustom
                fullScreen={props.fullScreen}
                open={opened}
                onClose={(event, reason) => {
                  if (props.blockBackdropClick && reason === 'backdropClick') {
                    return;
                  }
                  return this.close(modal);
                }}
                dialogTitle={params.title || props.title}
                actions={props.actions}
                closeIconVisible
                {...params.dialogProps}
              >
                {React.cloneElement(props.children, { ...params })}
              </DialogCustom>
            </LazyComponent>
          );
        })}
      </ModalsContext.Provider>
    );
  }
}

function withModals(Children) {
  return function WrappedComponent(props) {
    return (
      <ModalsContext.Consumer>
        {data => <Children {...props} modals={data} />}
      </ModalsContext.Consumer>
    );
  };
}

ModalsProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

const ModalsProvider = withTranslation(['common', 'restrict'])(ModalsProviderComponent);

const useModalsContext = () => useContext(ModalsContext);

export { ModalsContext, ModalsProvider, useModalsContext, withModals };
