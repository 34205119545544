import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { UPDATE_COMPANY_SPECIFIC_SYNONYM } from '../graphql/mutations';

const useUpdateCompanySynonyms = ({ form, id }) => {
  const [updateCompanySynonym, { error, loading }] = useMutation(UPDATE_COMPANY_SPECIFIC_SYNONYM);

  const useUpdateCompanySpecificCallback = useCallback(async () => {
    await updateCompanySynonym({
      variables: { record: { ...form }, id: id },
      update: cache => {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'customerSpecificSynonyms' });
      },
    });
  }, [updateCompanySynonym, form]);

  return {
    updateCompanySpecific: useUpdateCompanySpecificCallback,
    isLoading: loading,
    error,
  };
};

export default useUpdateCompanySynonyms;
