import { gql } from '@apollo/client';

import { CompanyOpportunityDetailsFields } from 'UI/pages/restrict/opportunities/CompanyOpportunities/CompanyOpportunityFields.fragment';

export const GET_COMPANY_OPPORTUNITIES_DETAILS = gql`
  ${CompanyOpportunityDetailsFields}

  query GET_COMPANY_OPPORTUNITIES_DETAILS($_id: ID!, $personId: ID) {
    companyOpportunityDetails(_id: $_id, personId: $personId) {
      ...CompanyOpportunityDetailsFields
      ads
      contactEmail
      follow
      name
      type
    }
  }
`;
