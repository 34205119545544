import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { UnsupportedBrowserPage } from 'UI/containers/UnsupportedBrowserPage/UnsupportedBrowserPage';
import { UserProvider } from 'UI/contexts/user';
import useConfig from 'UI/customHooks/useConfig';

import './polyfills';
import { getAuthCallbackError } from './auth/getAuthCallbackError';
import { checkUserSession } from './auth/userCredentials';
import BaseHelmet from './BaseHelmet';
import createI18next from './createI18next';
import { env } from './env';
import getApolloClient from './getApolloClient';
import loadTenantConfig from './loadTenantConfig';
import RouterSwitch from './RouterSwitch';
import AuthErrorPage from './ui/pages/auth/AuthErrorPage';
import ErrorBoundaryRoot from './ui/pages/ErrorBoundaryRoot/ErrorBoundaryRoot';
import { detect } from 'detect-browser';

const browserIsUnsupported = () => {
  const { blockedBrowsers } = useConfig(); // eslint-disable-line
  if (!blockedBrowsers) {
    return false;
  }
  const browser = detect();
  // We expect the `blockedBrowsers` in config contains the list of Browser's Major versions below which we do not allow.
  return blockedBrowsers.find(({ name, version }) => {
    const browserMajorVersion = browser.version.split('.')[0];
    return name === browser.name && browserMajorVersion > version;
  });
};

const renderView = (root, component) => {
  root.render(
    <>
      <BaseHelmet />
      {component}
    </>
  );
};

const startup = async () => {
  const container = document.getElementById('app');
  const root = createRoot(container);

  const { VITE_ENVIRONMENT, VITE_SENTRY_DNS, VITE_VERSION } = env;
  console.debug(`💻 starting ${VITE_ENVIRONMENT} app...`);

  if (VITE_SENTRY_DNS?.trim()) {
    Sentry.init({
      dsn: VITE_SENTRY_DNS,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
      environment: VITE_ENVIRONMENT || 'NA',
      release: VITE_VERSION,
    });
  }

  renderView(root, <span />);
  console.debug('🔧 waiting for the backend...');

  try {
    await loadTenantConfig();

    const mandatoryFields = ['clientConfig', 'PALYX_WHITE_LABEL', 'rootOrganizationId'];

    for (const field of mandatoryFields) {
      if (!window[field]) {
        throw new Error(`${field} global config is not loaded. Abort`);
      }
    }
  } catch (error) {
    console.error(error, 'Unable to load tenant config. Abort');
    return renderView(root, <ErrorBoundaryRoot error={error} />);
  }

  const apolloClient = getApolloClient();
  const i18next = createI18next();

  if (browserIsUnsupported()) {
    return renderView(root, <UnsupportedBrowserPage />);
  }

  const authError = await getAuthCallbackError();

  if (authError) {
    return renderView(
      root,
      <AuthErrorPage status={Number(authError.status)} reason={authError.reason} />
    );
  }

  const isRedirecting = await checkUserSession();

  if (isRedirecting) {
    return <span />;
  }

  renderView(
    root,
    <ErrorBoundaryRoot>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18next}>
          <Router>
            <UserProvider>
              <RouterSwitch />
            </UserProvider>
          </Router>
        </I18nextProvider>
      </ApolloProvider>
    </ErrorBoundaryRoot>
  );

  console.debug(`🚀 ready!`);
};

window.addEventListener('load', async () => {
  startup();
});
