import React from 'react';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useConfig from 'UI/customHooks/useConfig';
import { getFullCdnUrl } from 'UI/lib/getUrlType';

import get from 'lodash/get';

const useStyles = makeStyles({
  absoluteImage: {
    position: 'absolute',
    left: 0,
    top: 0,
    opacity: 0,
    transition: '0.25s',
  },
});

const CompanySpecificLogo = ({
  skill,
  style,
  styleAbsolute,
  height = 25,
  shouldLogoChangeOnHover,
  replaceDefaultLogo,
  ...otherProps
}) => {
  const pegasusConfig = useConfig();

  const { cdnUrl } = pegasusConfig;
  const { skillsLogo, skillButton } = window.PALYX_WHITE_LABEL;

  const classes = useStyles();
  const isCompanySpecificSkillsEnabled = get(
    pegasusConfig,
    `cms.features.skills.companySpecific.isEnabled`,
    false
  );

  const correctAlternativeSkillLogo = getFullCdnUrl(skillButton?.alternativeSkillsLogo, cdnUrl);
  const alternativeSkillsLogo = correctAlternativeSkillLogo ?? getFullCdnUrl(skillsLogo, cdnUrl);
  if (isCompanySpecificSkillsEnabled) {
    return (
      <Box sx={{ display: 'flex', position: 'relative' }} data-test="company-specific-logo">
        {shouldLogoChangeOnHover && (
          <img
            alt=""
            className={classes.absoluteImage}
            style={{ height: height, ...style }}
            src={alternativeSkillsLogo}
          />
        )}
        <img
          alt=""
          src={replaceDefaultLogo ? alternativeSkillsLogo : getFullCdnUrl(skillsLogo, cdnUrl)}
          height={`${height}px`}
          width="auto"
          style={{ marginRight: 5, ...style }} // default styling and custom
          {...otherProps}
        />
      </Box>
    );
  }
  return null;
};

export default CompanySpecificLogo;
