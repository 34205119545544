const style = () => ({
  root: {
    '&$disabled': {
      background: '#9b9b9b',
      color: '#fff',
      opacity: '0.6',
      border: 0,
    },
    '&:active $label': {
      position: 'relative',
    },
  },
  tertiaryRoot: {
    background: 'linear-gradient(282deg, #a582ff, #00c4ec)',
  },
  tertiaryContained: {
    '&:hover': {
      background: '#333333',
    },
  },
  //  The following should not be deleted.
  // Otherwise the disable wont work - Mui docs says.
  disabled: {},
  label: {},
});

export default style;
