import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/client';

import useConfig from 'UI/customHooks/useConfig';
import { clientLogger } from 'UI/lib';

import { getBrowserUserCredentials } from '../../../../../../auth/userCredentials';
import { GET_COURSE_OPPORTUNITIES_LXP } from './queries';
import get from 'lodash/get';

const useCourseOpportunitiesLXP = () => {
  const { palyxToken } = getBrowserUserCredentials();
  const config = useConfig();
  const LXPCoursesSource =
    config.cms?.pages?.personalDevelopment?.recommendations?.courses?.source === 'LXP';
  const location = useLocation();

  const { loading, data, error: queryError } = useQuery(GET_COURSE_OPPORTUNITIES_LXP, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    skip:
      !palyxToken ||
      !LXPCoursesSource ||
      location.pathname !== '/app/personal-development/recommendations',
  });

  useEffect(() => {
    if (queryError) {
      clientLogger.error(`useCourseOpportunities - ${queryError.message}`, queryError);
    }
  }, [queryError]);

  return {
    loading,
    data: get(data, 'coursesOpportunities'),
    error: queryError,
  };
};

export default useCourseOpportunitiesLXP;
