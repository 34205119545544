import React, { useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles } from '@mui/styles';

import LazyComponent from 'UI/components/LazyComponent/LazyComponent';
import { Notes } from 'UI/components/Notes';
import { useT } from 'UI/lib';

import styles from './NotesArea.style';

const useStyles = makeStyles(styles);

const NOTES_NAMES = {
  personalNotes: 'personal_notes',
  companyNotes: 'company_notes',
};

const UpdateDevelopmentNotesModal = ({ name, notesValue, handleSingleSubmit, onFinish }) => {
  const classes = useStyles();
  const [localNotes, setLocalNotes] = useState({ [name]: notesValue });
  const [blockActions, setBlockActions] = useState(false);
  const t = useT();
  const handleLocalNotes = value => {
    setLocalNotes({ [name]: value });
  };

  const handleSave = () => {
    setBlockActions(true);
    handleSingleSubmit(name, localNotes)
      .then(() => onFinish())
      .catch(() => {
        setBlockActions(false);
      });
  };

  return (
    <Box sx={{ maxWidth: { xs: 800, md: 'inherit' }, width: { xs: 'inherit', md: 800 } }}>
      <Grid container flexDirection="column">
        <Grid item>
          <Typography
            data-test="content-notes-text"
            mb={4}
            component="p"
            variant="h6"
            sx={{ fontWeight: 600 }}
          >
            {t(`restrict:personal_development.dialog.content_${NOTES_NAMES[name]}`)}
          </Typography>
        </Grid>
        <Grid item>
          <LazyComponent>
            <Notes
              value={notesValue}
              onValueChange={value => handleLocalNotes(value)}
              className={classes.textEditor}
              disabled={blockActions}
            />
          </LazyComponent>
        </Grid>
      </Grid>
      <DialogActions>
        <Button
          variant="outlined"
          data-test="modal-button-cancel"
          onClick={onFinish}
          disabled={blockActions}
        >
          {t('common:cancel')}
        </Button>
        <Button
          variant="contained"
          type="submit"
          data-test="modal-button-add"
          onClick={handleSave}
          disabled={localNotes[name] === notesValue || blockActions}
          sx={{ textTransform: 'capitalize' }}
        >
          {t('common:save')}
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UpdateDevelopmentNotesModal;
