import { SKILL_TO_VALIDATE_FRAGMENT } from '../skillsToValidate.fragment';
import gql from 'graphql-tag';
export const GET_ALL_ASSIGNED_SKILL_VALIDATIONS = gql`
  query GET_ALL_ASSIGNED_SKILL_VALIDATIONS($policiesPreset: PoliciesPresetInput, $userId: ID, $suggestedAction: [UserValidateSkillSuggestedActionFilterEnum] = [empty, add]) {
    userValidateSkillsV2(suggestedAction: $suggestedAction, userId: $userId) {
      b2b {
        ${SKILL_TO_VALIDATE_FRAGMENT}
      }
      managerAssessment {
        ${SKILL_TO_VALIDATE_FRAGMENT}
      }
      personalDevelopmentMeasure {
        ${SKILL_TO_VALIDATE_FRAGMENT}
      }
      selfAdded {
        ${SKILL_TO_VALIDATE_FRAGMENT}
      }
    }
  }
`;

// Only being used into the optimistic response
export const QUERY_ASSIGNED_SKILL_VALIDATIONS = gql`
  query GET_SELF_ASSIGNED_SKILL_VALIDATIONS(
        $policiesPreset: PoliciesPresetInput 
  ) {
    userValidateSkillsV2 {
      ${SKILL_TO_VALIDATE_FRAGMENT}
      suggestedAction
      suggestedBy {
        profile {
          name
        }
      }
    }
  }
`;
