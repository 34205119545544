import { Helmet } from 'react-helmet';

import { env } from './env';

const BaseHelmet = () => {
  const { VITE_CDN_URL, VITE_ENVIRONMENT } = env;

  const cssUrl = `${VITE_CDN_URL}/assets/css`;
  const cssEnv = VITE_ENVIRONMENT?.toLowerCase()?.includes('prod') ? 'prod' : 'staging';

  return (
    <Helmet>
      <link rel="stylesheet" href={`${cssUrl}/materialIcons_${cssEnv}.css`} />
      <link rel="stylesheet" href={`${cssUrl}/fonts_${cssEnv}.css`} />
    </Helmet>
  );
};

export default BaseHelmet;
