import { clientLogger } from 'UI/lib';
import { GET_COMPANY_OPPORTUNITIES_DETAILS } from 'UI/pages/restrict/opportunities/CompanyOpportunities/companyOpportunityDetails';

import {
  CompanyOpportunityDetailsFields,
  CompanyOpportunityFields,
} from './CompanyOpportunities/CompanyOpportunityFields.fragment';
import { MarketOpportunityFields } from './MarketOpportunities/MarketOpportunityFields.fragment';
import { OpportunitySkillFields } from './OpportunitySkillFields.fragment';
import intersection from 'lodash/intersection';

const MARKET_OPPORTUNITY = 'MarketOpportunity';
const COMPANY_OPPORTUNITY = 'CompanyOpportunity';

const buildSkill2SkillSearch = store => {
  const temporaryCache = {};
  return id => {
    if (!temporaryCache[id]) {
      const opportunitySkill = store.readFragment({
        fragment: OpportunitySkillFields,
        id,
      });

      temporaryCache[id] = opportunitySkill?.skillId;
    }

    return temporaryCache[id];
  };
};

const getIsMarketOpportunity = key => key.startsWith(MARKET_OPPORTUNITY);
const getIsCompanyOpportunity = key => key.startsWith(COMPANY_OPPORTUNITY);

const getIdForOpportunity = (store, fragment, opportunityKey) => {
  const opportunity = store.readFragment({
    fragment,
    fragmentName: fragment.mainFragmentName,
    id: opportunityKey,
  });
  return opportunity?._id;
};

const getIdForCompanyOpportunity = (store, key) =>
  getIdForOpportunity(store, CompanyOpportunityFields, key);

const getIdForMarketOpportunity = (store, key) =>
  getIdForOpportunity(store, MarketOpportunityFields, key);

const extractCachedEntriesToUpdate = (addedSkillIds, { store, getSkillIdFromOppSkillId }) => {
  const allCacheEntries = store.extract();
  const cacheEntryKeys = allCacheEntries ? Object.keys(allCacheEntries) : [];

  if (!cacheEntryKeys.length) {
    return [];
  }
  const cacheKeys = cacheEntryKeys.filter(
    key => getIsMarketOpportunity(key) || getIsCompanyOpportunity(key)
  );

  const opportunityIdsToUpdate = cacheKeys.filter(key => {
    const { mostRelevant = [], relevant = [] } = allCacheEntries[key]?.missing || {};

    const mostRelevantSkillIds = mostRelevant.map(({ __ref: id }) => getSkillIdFromOppSkillId(id));
    const relevantSkillIds = relevant.map(({ __ref: id }) => getSkillIdFromOppSkillId(id));

    return (
      intersection(mostRelevantSkillIds, addedSkillIds).length ||
      intersection(relevantSkillIds, addedSkillIds).length
    );
  });

  return opportunityIdsToUpdate.map(key => {
    if (getIsMarketOpportunity(key)) {
      return {
        type: MARKET_OPPORTUNITY,
        opportunityId: getIdForMarketOpportunity(store, key),
      };
    }
    return {
      type: COMPANY_OPPORTUNITY,
      opportunityId: getIdForCompanyOpportunity(store, key),
    };
  });
};

// This method removes skills from missing and add to matching ones
export const updateSkillsInCachedOpportunities = (store, addedSkillIds = []) => {
  try {
    const getSkillIdFromOppSkillId = buildSkill2SkillSearch(store);
    const cachedEntriesToUpdate = extractCachedEntriesToUpdate(addedSkillIds, {
      store,
      getSkillIdFromOppSkillId,
    });

    if (!cachedEntriesToUpdate.length) {
      return;
    }

    cachedEntriesToUpdate.forEach(({ type, opportunityId }) => {
      const fragment =
        type === MARKET_OPPORTUNITY ? MarketOpportunityFields : CompanyOpportunityDetailsFields;
      const cachedKey = `${type}:${opportunityId}`;
      const companyOpportunityDetailsFn = () => {
        if (type === COMPANY_OPPORTUNITY) {
          const cachedOpportunityDetails = store.readQuery({
            query: GET_COMPANY_OPPORTUNITIES_DETAILS,
            variables: { _id: opportunityId },
          });
          return cachedOpportunityDetails.companyOpportunityDetails;
        }
        return [];
      };
      const companyOpportunityDetails = companyOpportunityDetailsFn();
      const cachedOpportunity = store.readFragment({
        fragmentName: fragment.mainFragmentName,
        fragment,
        id: cachedKey,
      });

      if (!cachedOpportunity) {
        return;
      }

      const chosenCachedOpportunity =
        type === MARKET_OPPORTUNITY ? cachedOpportunity : companyOpportunityDetails;

      const { matching, missing } = chosenCachedOpportunity;

      if (!missing) {
        return;
      }

      const updatedData = {
        __typename: type,
        ...cachedOpportunity,
        missing: {
          ...chosenCachedOpportunity.missing,
          mostRelevant: missing.mostRelevant.filter(
            ({ skillId }) => !addedSkillIds.includes(skillId)
          ),
          relevant: missing.relevant.filter(({ skillId }) => !addedSkillIds.includes(skillId)),
        },
        matching: {
          ...chosenCachedOpportunity.matching,
          mostRelevant: [
            ...addedSkillIds
              .map(addedSkillId => {
                return (
                  missing.relevant.find(({ skillId }) => skillId === addedSkillId) ||
                  missing.mostRelevant.find(({ skillId }) => skillId === addedSkillId)
                );
              })
              .filter(Boolean),
            ...matching.mostRelevant,
          ],
        },
      };

      store.writeFragment({
        id: cachedKey,
        fragment,
        fragmentName: fragment.mainFragmentName,
        data: updatedData,
      });
    });
  } catch (error) {
    clientLogger.error(error);
  }
};
