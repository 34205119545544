import gql from 'graphql-tag';

const USER_PROFILE_UPDATE = gql`
  mutation USER_PROFILE_UPDATE($record: UpdateUserProfileInput!) {
    userProfileUpdate(record: $record) {
      recordId
      record {
        __typename
        _id
        profile {
          __typename
          firstName
          lastName
          avatar
          email
          nationality {
            code
            name
          }
          workPermit {
            id
            name
          }
        }
      }
    }
  }
`;

const LOGIN_USER_WITH_PASSWORD = gql`
  mutation LOGIN_USER_WITH_PASSWORD(
    $username: String!
    $password: String!
    $organizationToken: String
  ) {
    loginUserWithPassword(
      username: $username
      password: $password
      organizationToken: $organizationToken
    ) {
      id
      lastSelectedLanguage
    }
  }
`;

export { LOGIN_USER_WITH_PASSWORD, USER_PROFILE_UPDATE };
