const primary = '#005190';
const secondary = '#0090ff';
const tertiary = '#0090ff';
const fourth = '#00b674';
const fifth = '#818387';
const sixth = '#c8c8c8';
const seventh = '#bda3ff';
const eighth = '#dc3545';
const ninth = '#9b9b9b';
const tenth = '#626366';
const eleventh = '#005190';
const twelfth = 'rgba(0, 144, 255, 0.15)';
const thirteen = 'linear-gradient(282deg, #a582ff, #00c4ec)';
const fourteen = 'linear-gradient(103deg, #41cd8c, #00b698)';
const fifteen = 'linear-gradient(103deg, #00b698, #41cd8c)';
const sixteen = 'linear-gradient(282deg, #00c4ec, #a582ff)';
const seventeen = 'rgba(80, 164, 245, 0.19)';
const twenty = '#D2E0F7';
const twentyOne = '#261d3d';
const twentyTwo = '#ffa634';
const twentyThree = '#44346d';
const darkBlue = 'rgb(0, 56, 100)';
const unit = 5;

const theme = {
  maxWidth: 1440,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 480,
      sm: 768,
      md: 992,
      lg: 1280,
      xl: 1441,
      xxl: 1700,
    },
  },
  spacing: 2,
  palette: {
    custom: {
      primary,
      secondary,
      tertiary,
      fourth,
      fifth,
      sixth,
      seventh,
      eighth,
      ninth,
      tenth,
      eleventh,
      twelfth,
      thirteen,
      fourteen,
      fifteen,
      sixteen,
      twenty,
      twentyOne,
      twentyTwo,
      twentyThree,
    },
    text: {
      disabled: 'rgba(255,255,255,0.7)',
    },
  },
  fontFamily: ['Work Sans', 'sans-serif'].join(','),
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
    button: {
      fontSize: 16,
    },
    fontWeight: 500,
    h4: {
      fontSize: 35,
      fontWeight: 300,
      lineHeight: 1.29,
      letterSpacing: 'normal',
      color: primary,
    },
    h3: {
      fontSize: 22,
      fontWeight: 'normal',
      lineHeight: 1.27,
      letterSpacing: 'normal',
      color: primary,
    },
    h2: {
      fontSize: 21,
      fontWeight: '600',
      letterSpacing: 'normal',
      color: primary,
    },
    h1: {
      fontSize: 70,
      fontWeight: 300,
      lineHeight: 1.06,
      letterSpacing: 'normal',
      color: primary,
    },
    h6: {
      fontSize: 44,
      fontWeight: 300,
      letterSpacing: 'normal',
      color: primary,
    },
    subtitle1: {
      fontSize: 29,
      fontWight: 300,
    },
    body1: {
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      lineHeight: 1.44,
      letterSpacing: 'normal',
      color: primary,
    },
  },
  overrides: {
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        backgroundColor: 'rgba(80, 164, 245, 0.19)',
      },
      barColorPrimary: {
        backgroundColor: '#005190',
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: '#005190',
        '&$checked': {
          color: '#005190',
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: '#005190',
      },
      colorPrimary: {
        color: '#005190',
        '&$checked': {
          color: '#005190',
        },
      },
    },
    MuiTableCell: {
      body: {
        color: '#fff',
      },
      head: {
        color: 'rgba(255,255,255,0.7)',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        color: primary,
        borderRadius: 20,
        padding: '0 15px',
        minHeight: 40,
        height: 'auto',
        fontSize: 18,
        lineHeight: '1.3em',
        wordBreak: 'break-all',
        '&:hover': {
          color: secondary,
          backgroundColor: 'transparent',
        },
      },
      label: {
        textTransform: 'none',
        '@media all and (-ms-high-contrast:none)': {
          lineHeight: 2,
        },
      },
      sizeLarge: {
        borderRadius: 24,
        paddingLeft: unit * 4,
        paddingRight: unit * 4,
        lineHeight: 0,
        minHeight: 48,
      },
      sizeSmall: {
        borderRadius: 16,
        paddingLeft: unit * 3,
        paddingRight: unit * 3,
        minHeight: 32,
      },
      outlined: {
        borderWidth: 2,
        borderStyle: 'solid',
        //  borderColor: 'inherit',
        borderColor: primary,
        backgroundColor: 'white',
        padding: '0 15px',
        '&:hover': {
          backgroundColor: primary,
          color: '#fff',
        },
      },
      contained: {
        border: '2px solid transparent',
        color: 'white',
        backgroundColor: primary,
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover': {
          backgroundColor: '#9b9b9b',
          color: '#fff',
        },
        '@media all and (-ms-high-contrast:none)': {
          lineHeight: '2.3 !important',
        },
      },
      containedPrimary: {
        color: '#fff',
        backgroundColor: primary,
        '&:hover': {
          color: '#fff',
          backgroundColor: darkBlue,
        },
      },
      containedSecondary: {
        color: 'white',
        background: fourteen,
        '&:hover:not($disabled)': {
          background: fifteen,
        },
      },
      textSecondary: {
        color: fourth,
      },
    },
    MuiIconButton: {
      root: {
        color: primary,
      },
      colorPrimary: {
        color: '#005190',
      },
    },
    MuiToolbar: {
      root: {
        color: primary,
      },
    },
    MuiInput: {
      root: {
        color: ninth,
        height: 36,
        alignItems: 'center',
        '&$focused': {
          color: secondary,
        },
      },
      input: {
        fontSize: 16,
        fontWeight: 500,
        padding: 0,
      },
      underline: {
        borderBottom: `1px solid ${secondary}`,
        '&:hover:not($disabled):not($error):not($focused)::before': {
          borderBottom: `1px solid ${secondary}`,
        },
        '&::before': {
          borderBottom: `2px solid ${secondary}`,
        },
        '&::after': {
          borderBottom: `1px solid ${secondary}`,
        },
        '&:hover': {
          borderBottom: `1px solid ${secondary}`,
        },
      },
    },
    MuiSelect: {
      root: {
        alignItems: 'center',
        display: 'flex',
        borderBottom: '0 !important',
      },
      selectMenu: {
        width: '100%',
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 16,
        fontWeight: 500,
        padding: '0 10px',
        height: 36,
        color: primary,
        '&$selected': {
          color: primary,
          backgroundColor: `${twelfth} !important`,
        },
      },
    },
    MuiListItem: {
      default: {},
      button: {
        color: primary,
        '&:focus': {
          color: primary,
        },
        '&:focused': {
          color: primary,
        },
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        minHeight: 50,
      },
      content: {},
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: primary,
        },
      },
    },
    MuiTableSortLabel: {
      root: {
        color: `${primary} !important`,
      },
    },
    MuiTablePagination: {
      selectRoot: {
        marginTop: -6,
      },
      select: {
        fontWeight: 500,
        fontSize: 13,
        marginTop: 7,
        marginRight: 5,
      },
    },
    MuiLabel: {
      color: primary,
    },
    MuiSwitch: {
      colorPrimary: {
        color: primary,
      },
      colorSecondary: {
        color: primary,
        '&$checked': {
          color: primary,
        },
      },
      iconChecked: {
        color: primary,
      },
    },
    AutoComplete: {
      root: {
        color: '#9b9b9b',
        height: 36,
        alignItems: 'center',
        '&$focused': {
          color: primary,
        },
      },
      input: {
        fontSize: 16,
        fontWeight: 500,
        padding: 0,
      },
      underline: {
        '&:before': {
          borderBottom: `2px solid #001155`,
        },
      },
    },
  },
  customElements: {
    dropdownContent: {
      background: twentyOne,
      color: '#fff',
    },
    snackBar: {
      default: {
        backgroundColor: `${primary} !important`,
      },
    },
  },
  customPages: {
    onboarding: {
      backgroundColor: seventeen,
      welcome: {
        h4: {
          fontSize: 28,
        },
        background: `url('/assets/onboarding/new/welcome_bg.png') calc(100% - 50px) / 550px no-repeat, ${seventeen}`,
      },
      personalDevelopment: {
        background:
          "url('/assets/onboarding/new/work_ecosystem.png') 700px 100px / 600px no-repeat",
      },
      opportunities: {
        background:
          "url('/assets/onboarding/new/careers_tandem_bicycle.png') right 200px / 700px no-repeat",
      },
    },
    settings: {
      background: `url('/assets/restrict/setting_bt.png') center right / 710px no-repeat, ${twenty}`,
    },
    dashboard: {
      welcomeMessage: {
        isCurved: true,
        css: {
          color: '#fff',
          display: 'flex',
          alignItems: 'center',
        },
      },
      cards: {
        isCurved: true,
      },
    },
    loggedOut: {
      background: `url('/assets/onboarding/new/welcome_bg.png') 90% / 460px no-repeat, ${seventeen}`,
    },
    error: {
      background: `url('/assets/common/errorPage.svg') right 85% / 700px no-repeat, ${seventeen}`,
    },
    deletedAccount: {
      background: `url('/assets/onboarding/new/welcome_bg.png') 90% / 460px no-repeat,  #D1E6F9`,
    },
    company: {
      capabilities: {
        expanded: {
          optionsMiddleSection: {
            input: {
              borderBottom: '1px solid #fff',
              color: '#fff',
              underline: {
                '&:hover': {
                  borderBottom: 0,
                },
              },
            },
            textField: {
              minHeight: 81,
              padding: 3,
              borderBottom: '1px solid #fff',
              color: '#fff',
            },
            icon: {
              color: '#fff',
            },
            underline: {
              borderBottom: 0,
              '&:hover:not(.MuiInput-disabled):not(.MuiInput-error):not(.MuiInput-focused)::before': {
                borderBottom: 0,
              },
              '&::before': {
                borderBottom: 0,
              },
              '&::after': {
                borderBottom: 0,
              },
              '&:hover': {
                borderBottom: 0,
              },
            },
          },
          topMembers: {
            selected: {
              backgroundColor: '#005190 !important',
              color: '#fff !important',
            },
            hovered: {
              backgroundColor: 'rgba(80, 164, 245, 0.19)',
            },
            backgroundColor: 'transparent',
            color: '#fff !important',
          },
        },
      },
      employees: {
        expanded: {
          optionsMiddleSection: {
            capabilityAssign: {
              tooltipIcon: {
                background: 'transparent',
                textColor: '#fff',
              },
              tooltipPopper: {
                textColor: '#fff',
              },
            },
            assignCapabilitiesList: { textColor: '#FFFFFF' },
            input: {
              color: '#fff',
            },
          },
        },
      },
      skillInventory: {},
    },
  },
};

export default theme;
