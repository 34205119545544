import React from 'react';

import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import styles from './styles';
import noop from 'lodash/noop';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';

const ALLOWED_TO_OVERWRITE_CLASSES = [
  'root',
  'label',
  'text',
  'textPrimary',
  'textSecondary',
  'flat',
  'flatPrimary',
  'flatSecondary',
  'outlined',
  'outlinedPrimary',
  'outlinedSecondary',
  'contained',
  'containedPrimary',
  'containedSecondary',
  'raised',
  'raisedPrimary',
  'raisedSecondary',
  'fab',
  'extendedFab',
  'focusVisible',
  'disabled',
  'colorInherit',
  'mini',
  'sizeSmall',
  'sizeLarge',
  'fullWidth',
  'startIcon',
  'endIcon',
];

class ButtonCustom extends React.Component {
  onClick = event => {
    const { stopPropagation, onClick } = this.props;
    if (stopPropagation) {
      event.stopPropagation();
    }
    onClick(event);
  };

  render() {
    const {
      color,
      children,
      classes,
      labelClasses,
      loading,
      fontWeight,
      style,
      stopPropagation,
      sxProps = {},
      ...otherProps
    } = this.props;

    // This is a hack. We are sending many props in `classes` object which MUI
    // does not expect
    const filteredClasses = pick(classes, ALLOWED_TO_OVERWRITE_CLASSES);

    return (
      <Button
        {...otherProps}
        // color={color}
        style={style}
        // TODO: we keep this prop for now, cause it affects stylings of the app
        // (white label selector styles apply some styles because of this prop)
        disableRipple
        classes={{
          root: classes.root,
          disabled: classes.disabled,
          ...filteredClasses,
        }}
        sx={{
          '&:focus-visible': {
            outline: '2px solid black !important',
          },
          ...sxProps,
        }}
        onClick={this.onClick}
      >
        {children}
      </Button>
    );
  }
}

ButtonCustom.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  fontWeight: PropTypes.number,
  classes: PropTypes.object,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'tertiary']),
  labelClasses: PropTypes.string,
  stopPropagation: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  startIcon: PropTypes.node,
  endIcon: PropTypes.node,
};

ButtonCustom.defaultProps = {
  variant: 'contained', // "text", "flat", "outlined", "contained", "raised", "fab"
  fontWeight: 600,
  color: 'default',
  labelClasses: undefined,
  onClick: noop,
  disabled: false,
};

export default withStyles(styles)(ButtonCustom);
