import React, { useContext } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import useAvailableLanguages from 'UI/customHooks/useAvailableLanguages';
import useConfig from 'UI/customHooks/useConfig';
import { clientLogger, navigateInternal } from 'UI/lib';

import { AUTH_ERROR_CODE_QUERY_PARAM, AUTH_ERROR_REASON_QUERY_PARAM } from '../../auth/constants';
import { getBrowserUserCredentials, logoutUser } from '../../auth/userCredentials';
import getApolloClient from '../../getApolloClient';
import { LOGIN_USER_WITH_PASSWORD, USER_PROFILE_UPDATE } from '../mutations/user';
import { GET_VIEWER } from '../queries/viewer';
import i18next from 'i18next';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import queryString from 'query-string';

const INITIAL_STATE = {
  currentUser: null,
  loginError: null,
  isUserBeingLoaded: false,
};

export const UserContext = React.createContext();

class UserProviderComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    let organizationToken = null;

    if (props.location.search) {
      organizationToken = queryString.parse(props.location.search).token;
    }

    this.state = {
      organizationToken,
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const { currentUser } = this.state;

    if (!currentUser) {
      try {
        this.getCurrentUser();
      } catch (error) {
        clientLogger.error('Error while resolving user', error);
      }
    }
  }

  getAuthErrorReason = error => {
    const switchMessage = {
      UNAUTHORIZED: this.props.t('auth:login_invalid_credentials'),
      EMAIL_EXISTS: this.props.t('auth:error_email_exists'),
      TOKEN_INVALID: this.props.t('auth:error_token_invalid'),
      FORBIDDEN: this.props.t('auth:error_forbidden'),
      ACCOUNT_LOCKED: this.props.t('auth:error_account_locked'),
    };

    return (
      switchMessage[error?.graphQLErrors?.[0]?.message] ||
      switchMessage[error?.message] ||
      this.props.t('auth:unexpected_error')
    );
  };

  getCurrentUser = () => {
    const config = useConfig();
    const { palyxToken } = getBrowserUserCredentials();
    const availableLanguages = useAvailableLanguages();
    const defaultLang = availableLanguages.find(language => language.isDefault).languageCode;
    const activeLanguages = config?.cms?.features?.localization?.languages;

    if (!palyxToken) {
      return;
    }

    return new Promise(async (resolve, reject) => {
      this.setState({ isUserBeingLoaded: true });
      try {
        const { data, error } = await getApolloClient().query({
          fetchPolicy: 'no-cache',
          query: GET_VIEWER,
        });

        if (error) {
          throw error;
        }
        const { viewer: currentUser } = data;

        if (currentUser?.lastSelectedLanguage) {
          if (!activeLanguages[currentUser.lastSelectedLanguage]?.isActive) {
            await this.changeLanguage(defaultLang);
          } else {
            await this.changeLanguage(currentUser?.lastSelectedLanguage || defaultLang);
          }
        }

        if (!currentUser) {
          throw new Error('Failed to load viewer');
        }

        this.setState(
          {
            currentUser,
            isUserBeingLoaded: false,
          },
          () => resolve(currentUser)
        );
      } catch (error) {
        this.setState({ isUserBeingLoaded: false }, () => {
          reject(error);
        });
      }
    }).catch(error => {
      clientLogger.error(error, 'Error while resolving user');
      navigateInternal(
        `/?${AUTH_ERROR_CODE_QUERY_PARAM}=500&${AUTH_ERROR_REASON_QUERY_PARAM}=Not possible to fetch viewer data`
      );
      return;
    });
  };

  onLoginError = error => {
    try {
      const graphQlError = get(error, 'graphQLErrors[0]');
      if (!graphQlError) {
        return false;
      }
      const extensionsCode = get(graphQlError, 'extensions.code');
      if (extensionsCode !== 'SSO_REQUIRED_ERROR') {
        return false;
      }
      const ssoProviderUrl = get(graphQlError, 'extensions.exception.ssoProviderUrl');

      this.setState(
        {
          loginError: {
            ssoProviderUrl,
          },
        },
        () =>
          logoutUser(
            `/?${AUTH_ERROR_CODE_QUERY_PARAM}=403&${AUTH_ERROR_REASON_QUERY_PARAM}=${graphQlError.message}`
          )
      );
      return true;
    } catch (e) {
      return false;
    }
  };

  changeLanguage(language) {
    return i18next.changeLanguage(language, async err => {
      if (err) {
        clientLogger.error('something went wrong loading', err);
      }
    });
  }

  login = async ({ email, password }) => {
    const { organizationToken } = this.state;

    try {
      const loginResponse = await getApolloClient().mutate({
        mutation: LOGIN_USER_WITH_PASSWORD,
        variables: {
          username: email,
          password,
          organizationToken,
        },
      });

      localStorage.removeItem('onboarding');

      if (organizationToken) {
        this.unsetOrganizationToken();
      }

      const user = loginResponse?.data?.loginUserWithPassword;

      if (user?.lastSelectedLanguage) {
        await this.changeLanguage(user.lastSelectedLanguage);
      }

      return user;
    } catch (error) {
      const isErrorHandled = this.onLoginError(error);
      if (!isErrorHandled) {
        return { error: this.getAuthErrorReason(error) };
      }
    }
  };

  logout = async () => {
    await logoutUser();
  };

  updateProfile = async options => {
    const { currentUser } = this.state;
    const handledOptions = options;

    const optimisticData = {
      ...currentUser,
    };

    Object.keys(options).forEach(field => {
      optimisticData.profile[field] = options[field];
    });

    return getApolloClient().mutate({
      mutation: USER_PROFILE_UPDATE,
      variables: {
        record: {
          ...handledOptions,
          _id: currentUser._id,
        },
      },
      optimisticResponse: {
        __typename: 'Mutation',
        userProfileUpdate: {
          __typename: 'UserUpdateProfilePayload',
          recordId: currentUser._id,
          record: {
            __typename: 'User',
            ...optimisticData,
          },
        },
      },
      update: () => {
        this.getCurrentUser();
      },
    });
  };

  render() {
    const { state } = this;
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          state,
          logout: this.logout,
          login: this.login,
          updateProfile: this.updateProfile,
          getCurrentUser: this.getCurrentUser,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

export const withUser = Children => {
  const WrappedComponent = props => (
    <UserContext.Consumer>{data => <Children {...props} user={data} />}</UserContext.Consumer>
  );

  return WrappedComponent;
};

UserProviderComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUserContext = () => useContext(UserContext);

export const useUserId = () => {
  const userInfo = useContext(UserContext);
  return get(userInfo, 'state.currentUser._id');
};

export const UserProvider = withTranslation('common')(withRouter(UserProviderComponent));
