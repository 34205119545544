import gql from 'graphql-tag';

export const OpportunitySkillFields = gql`
  fragment OpportunitySkillFields on OpportunitySkill {
    __typename
    _id
    actualExpertiseLevel
    requiredExpertiseLevel
    skillId
    skill {
      _id
    }
    isInValidation
  }
`;
