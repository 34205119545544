import { useMemo } from 'react';

import keyBy from 'lodash/keyBy';

export const useOpportunitySkills = ({
  userSkills,
  matchingSkills,
  missingSkills,
  skillsWithSynonyms,
}) => {
  const userSkillSynonymsMap = useMemo(() => {
    if (!userSkills?.length) {
      return {};
    }

    return keyBy(userSkills.map(userSkill => userSkill?.skill || {}).flat(), '_id');
  }, [userSkills]);

  const opportunitySkillSynonymsMap = useMemo(() => {
    if (!skillsWithSynonyms?.length) {
      return {};
    }
    return keyBy(skillsWithSynonyms, '_id');
  }, [skillsWithSynonyms]);

  const userSkillIdsWithZeroExpertise = useMemo(
    () => userSkills?.filter(({ level }) => level === null).map(({ skillId }) => skillId) ?? [],
    [userSkills]
  );

  const matchingMostRelevantSkills = useMemo(() => {
    if (!matchingSkills.mostRelevant?.length) {
      return [];
    }

    return matchingSkills.mostRelevant.reduce((skills, skill) => {
      const skillSynonym =
        opportunitySkillSynonymsMap[skill.skillId] || userSkillSynonymsMap[skill.skillId];
      const isSynonymValid = Boolean(skillSynonym?.synonym);

      if (!isSynonymValid) {
        return skills;
      }

      skills.push({
        ...skill,
        skill: skillSynonym,
      });

      return skills;
    }, []);
  }, [matchingSkills.mostRelevant, opportunitySkillSynonymsMap, userSkillSynonymsMap]);

  const matchingRelevantSkills = useMemo(() => {
    if (!matchingSkills.relevant?.length) {
      return [];
    }

    return matchingSkills.relevant.reduce((skills, skill) => {
      const skillSynonym =
        opportunitySkillSynonymsMap[skill.skillId] || userSkillSynonymsMap[skill.skillId];
      const isSynonymValid = Boolean(skillSynonym?.synonym);

      if (!isSynonymValid) {
        return skills;
      }

      skills.push({
        ...skill,
        skill: skillSynonym,
      });

      return skills;
    }, []);
  }, [matchingSkills.relevant, opportunitySkillSynonymsMap, userSkillSynonymsMap]);

  const matchingRelatedSkills = useMemo(() => {
    if (!matchingSkills.related?.length) {
      return [];
    }
    return matchingSkills.related.reduce((skills, skill) => {
      const skillSynonym =
        opportunitySkillSynonymsMap[skill.skillId] || userSkillSynonymsMap[skill.skillId];
      const isSynonymValid = Boolean(skillSynonym?.synonym);

      if (!isSynonymValid) {
        return skills;
      }

      skills.push({
        ...skill,
        skill: skillSynonym,
      });

      return skills;
    }, []);
  }, [matchingSkills.related, opportunitySkillSynonymsMap, userSkillSynonymsMap]);

  const missingMostRelevantSkills = useMemo(() => {
    if (!missingSkills.mostRelevant?.length || !skillsWithSynonyms?.length) {
      return [];
    }

    return missingSkills.mostRelevant.reduce((skills, skill) => {
      const skillSynonym = opportunitySkillSynonymsMap[skill.skillId];
      const isSynonymValid = Boolean(skillSynonym?.synonym);

      if (!isSynonymValid) {
        return skills;
      }

      skills.push({
        ...skill,
        skill: skillSynonym,
        userHasZeroExpertise: userSkillIdsWithZeroExpertise.includes(skill.skillId),
      });

      return skills;
    }, []);
  }, [
    missingSkills.mostRelevant,
    userSkillIdsWithZeroExpertise,
    opportunitySkillSynonymsMap,
    skillsWithSynonyms,
  ]);

  const missingRelevantSkills = useMemo(() => {
    if (!missingSkills.relevant?.length || !skillsWithSynonyms?.length) {
      return [];
    }

    return missingSkills.relevant.reduce((skills, skill) => {
      const skillSynonym = opportunitySkillSynonymsMap[skill.skillId];
      const isSynonymValid = Boolean(skillSynonym?.synonym);

      if (!isSynonymValid) {
        return skills;
      }

      skills.push({
        ...skill,
        skill: skillSynonym,
        userHasZeroExpertise: userSkillIdsWithZeroExpertise.includes(skill.skillId),
      });

      return skills;
    }, []);
  }, [
    missingSkills.relevant,
    userSkillIdsWithZeroExpertise,
    opportunitySkillSynonymsMap,
    skillsWithSynonyms,
  ]);

  return {
    matchingMostRelevantSkills,
    matchingRelevantSkills,
    matchingRelatedSkills,
    missingMostRelevantSkills,
    missingRelevantSkills,
  };
};
